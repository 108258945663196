import React, { Component } from 'react';
import { string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import ReactTooltip from 'react-tooltip';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import { findCategoryByKey } from '../../util/categories';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';

import EmptyHeartIcon from './EmptyHeartIcon';
import FullHeartIcon from './FullHeartIcon';

import css from './ListingCard.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    currentUser,
    listing,
    renderSizes,
    setActiveListing,
    onUpdateProfile,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorPublicData = author.attributes.profile.publicData;
  const authorFirstName =
    authorPublicData && authorPublicData.firstName ? authorPublicData.firstName : null;
  const authorLastName =
    authorPublicData && authorPublicData.lastName ? authorPublicData.lastName : null;
  const authorFullName =
    authorFirstName && authorLastName ? `${authorFirstName} ${authorLastName}` : null;
  const authorName = authorFullName ? authorFullName : author.attributes.profile.displayName;

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ListingCard.perNight'
    : isDaily
    ? 'ListingCard.perDay'
    : 'ListingCard.perUnit';
  const publicData = currentListing.attributes.publicData;
  const listingType = publicData ? publicData.type : null;
  const favoriteListingIds =
    currentUser && currentUser.attributes.profile.privateData.favoriteListingIds ?
      currentUser.attributes.profile.privateData.favoriteListingIds : [];
  const isFavoriteListing = favoriteListingIds.includes(id);

  const unitLabel = listingType === 'table' ? (
    <div className={css.perUnit}>
      <FormattedMessage id={unitTranslationKey} />
    </div>
  ) : null;

  let address =
    publicData && publicData.location && publicData.location.address ?
      publicData.location.address : null;
  if(address && publicData.location.building) {
    address += `, ${publicData.location.building}`;
  }

  const categoryOptions = findOptionsForSelectFilter('category', config.custom.filters);
  const category = publicData && publicData.category &&
    findCategoryByKey( publicData.category, categoryOptions ) || {};

  const toggleFavorite = e => {
    const newFavoriteListingIds = [...favoriteListingIds];
    const listingIndex = newFavoriteListingIds.indexOf( id );

    if( listingIndex >= 0 ){
      newFavoriteListingIds.splice(listingIndex, 1);
    } else {
      newFavoriteListingIds.push(id);
    }

    e.preventDefault();
    onUpdateProfile({ privateData: { favoriteListingIds: newFavoriteListingIds }});
  };

  const addressLine = address ? (
    <div className={css.contactInfo}>
      <div className={css.mainInfo}>
        <div className={css.authorInfo}>
          <b>Adresse</b> : {address}
        </div>
      </div>
    </div>
  ) : null;

  const emailLine = authorPublicData && authorPublicData.email ? (
    <div className={css.contactInfo}>
      <div className={css.mainInfo}>
        <div className={css.authorInfo}>
          <b>EMail</b> : {authorPublicData.email}
        </div>
      </div>
    </div>
  ) : null;

  const phoneLine = authorPublicData && authorPublicData.phoneNumber ? (
    <div className={css.contactInfo}>
      <div className={css.mainInfo}>
        <div className={css.authorInfo}>
          <b>Telefonnummer</b> : {authorPublicData.phoneNumber}
        </div>
      </div>
    </div>
  ) : null;

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div
        className={css.threeToTwoWrapper}
        onMouseEnter={() => setActiveListing(currentListing.id)}
        onMouseLeave={() => setActiveListing(null)}
      >
        <div className={css.aspectWrapper}>
          <LazyImage
            rootClassName={css.rootForImage}
            alt={title}
            image={firstImage}
            variants={['landscape-crop', 'landscape-crop2x']}
            sizes={renderSizes}
          />
        </div>
      </div>
      <div className={css.info}>
        <div className={css.price}>
          { !category.hide_price ? (
            <>
              <div className={css.priceValue} title={priceTitle}>
                {formattedPrice}
              </div>
              {unitLabel}
            </>
          ) : null }
          <div className={css.favorite}>
            { typeof window === 'object' ? <ReactTooltip /> : null }
            { isFavoriteListing ? (
              <FullHeartIcon
                onClick={toggleFavorite}
                data-tip={intl.formatMessage({ id: 'ListingCard.fullHeartTooltip'})}
              />
            ) : currentUser ? (
              <EmptyHeartIcon
                onClick={toggleFavorite}
                data-tip={intl.formatMessage({ id: 'ListingCard.emptyHeartTooltip'})}
              />
            ) : null }
          </div>
        </div>
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.authorInfo}>
            <FormattedMessage id="ListingCard.hostedBy" values={{ authorName }} />
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
