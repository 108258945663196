import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button, FieldTextInput } from '../../components';
import CustomUnitTypeSelectFieldMaybe from './CustomUnitTypeSelectFieldMaybe';

import css from './EditListingDescriptionForm.css';

const TITLE_MAX_LENGTH = 60;

const findCategoryByKey = (categoryKey, categoryOptions) => {
  let category = null;

  if(categoryKey && Array.isArray(categoryOptions)) {
    categoryOptions.forEach(option => {
      if (option.key === categoryKey) {
        category = option;
      }
    });
  }

  return category;
};

const buildCategoriesHash = categoryOptions => {
  const categoriesHash = {};

  if( Array.isArray(categoryOptions)) {
    categoryOptions.forEach( option => {
      categoriesHash[ option.key ] = option;
    });
  }

  return categoriesHash;
};

const buildCategoriesTree = categoryOptions => {
  const categoriesTreeHash = {};

  if(Array.isArray(categoryOptions)) {
    categoryOptions.forEach(option => {
      if ( !option.parent ){
        categoriesTreeHash[ option.key ] =
          { key: option.key, label: option.label, children: []};
      }
    });

    categoryOptions.forEach(option => {
      if ( option.parent && categoriesTreeHash[ option.parent ]) {
        categoriesTreeHash[ option.parent ].children.push({ children: [], ...option });
      }
    });

    categoryOptions.forEach(option => {
      if ( option.parent && !categoriesTreeHash[ option.parent ]) {
        let branchCategory = null;

        Object.keys( categoriesTreeHash ).forEach( topCategoryKey => {
          const topCategory = categoriesTreeHash[ topCategoryKey ];

          topCategory.children.forEach( childCategory => {
            if( childCategory.key === option.parent ) {
              branchCategory = childCategory;
            }
          });
        });

        branchCategory.children.push( option );
      }
    });

  }

  return categoriesTreeHash;
};

const findParentNodes = ( categoryId, categoriesHash ) => {
  const parentNodes = [];
  let curNodeId = categoryId;

  while( curNodeId ){
    if( categoriesHash[ curNodeId ].parent )
      parentNodes.push( categoriesHash[ curNodeId ].parent );

    curNodeId = categoriesHash[ curNodeId ].parent;
  }

  return parentNodes;
};

const EditListingDescriptionFormComponent = props => {
  const [categoryStamp, setCategoryStamp] = useState(Date.now());

  const setValue = ([fieldName, newValue], state, { changeValue }) => {
    changeValue(state, fieldName, () => newValue)
  };

  return(
    <FinalForm
      {...props}
      mutators={{ setValue }}
      render={formRenderProps => {
        const {
          categories,
          unitTypes,
          className,
          form,
          values,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
        } = formRenderProps;

        const onCategorySelect = (event, nodeId) => {
          form.mutators.setValue("category", nodeId );
          setCategoryStamp(Date.now());
        };

        const category = findCategoryByKey(values.category, categories);
        const categoriesHash = buildCategoriesHash( categories );
        const categoryOptions = buildCategoriesTree(categories);

        const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const descriptionMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.description',
        });
        const descriptionPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionPlaceholder',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionRequired',
        });

        const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed"/>
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError"/>
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed"/>
          </p>
        ) : null;

        const categoryLabel = intl.formatMessage({
          id: 'EditListingDescriptionForm.categoryLabel',
        });
        const categoryPlaceholder = intl.formatMessage({
          id: 'EditListingDescriptionForm.categoryPlaceholder',
        });
        const categoryRequired = required(
          intl.formatMessage({
            id: 'EditListingDescriptionForm.categoryRequired',
          })
        );

        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;

        const unitTypeDropdown = category && category.type === 'product' ? (
          <CustomUnitTypeSelectFieldMaybe
            id="unitType"
            name="unitType"
            unitTypes={unitTypes}
            category={category}
            intl={intl}
          />
        ) : null;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            <FieldTextInput
              id="title"
              name="title"
              className={css.title}
              type="text"
              label={titleMessage}
              placeholder={titlePlaceholderMessage}
              maxLength={TITLE_MAX_LENGTH}
              validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
              autoFocus
            />

            <FieldTextInput
              id="description"
              name="description"
              className={css.description}
              type="textarea"
              label={descriptionMessage}
              placeholder={descriptionPlaceholderMessage}
              validate={composeValidators(required(descriptionRequiredMessage))}
            />

            <span>{categoryLabel}</span>
            <FieldTextInput
              id="category"
              name="category"
              type="hidden"
              validate={categoryRequired}
            />
            <TreeView
              aria-label="Category"
              defaultCollapseIcon={<ExpandMoreIcon/>}
              defaultExpandIcon={<ChevronRightIcon/>}
              sx={{ height: 200, overflowY: 'auto' }}
              defaultSelected={values.category}
              defaultExpanded={findParentNodes( values.category, categoriesHash )}
              onNodeSelect={onCategorySelect}
            >
              {Object.values(categoryOptions).map(topOption => {
                return (
                  <TreeItem nodeId={topOption.key} label={topOption.label}>
                    {topOption.children ? topOption.children.map(childOption => {
                      return (
                        <TreeItem nodeId={childOption.key} label={childOption.label}>
                          {childOption.children ? childOption.children.map(leafOption => (
                            <TreeItem nodeId={leafOption.key} label={leafOption.label}/>
                          )) : null}
                        </TreeItem>
                      );
                    }) : null}
                  </TreeItem>
                );
              })}
            </TreeView>

            {unitTypeDropdown}

            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </Form>
        );
      }}
    />
  );
};

EditListingDescriptionFormComponent.defaultProps = { className: null, fetchErrors: null };

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  categories: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
