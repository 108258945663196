import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './FaqPage.css';

const FaqPage = () => {
  // prettier-ignore
  return (
    <StaticPage
      title="Frequently Asked Questions"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'FaqPage',
        description: 'Dorfplatz24 frequently asked questions',
        name: 'FAQ page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h1 className={css.pageTitle}><b>FAQ</b></h1>
          <h2 className={css.pageTitle}>FAQ Produzenten</h2>
          <h3 className={css.pageTitle}>Hier findest Du häufig gestellte Fragen von Produzenten.</h3>
          <h3 className={css.pageTitle}>Bei dringlichen Fragen, kontaktiere uns gerne.</h3>
          <br/>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p style={{fontWeight: 'bold'}}>
                Wie kann ich meine Produkte und Angebote auf Dorfplatz24 anbieten?
              </p>
              <p>
                Wenn Du deine Produkte oder Angebote über Dorfplatz24 anbieten möchtest,
                kannst Du online ein Profil von dir und deinem Geschäft erstellen
                sowie deine Produkte und Angebote erfassen.
              </p>
              <p>
                Zudem solltest Du gut belichtete Fotos hochladen. Unsere Erfahrung
                zeigt, dass die Anzahl und Qualität der Fotos einen grossen Einfluss
                auf die Beliebtheit bei den Nutzern hat. Sobald Du mit deinem Profil
                fertig bist, wird dieses durch uns freigeschalten. Nach der Freigabe
                kann's losgehen!
              </p>
              <p>
                Dorfplatz24 ist der Produktekatalog mit Kartensuche in der Schweiz.
                Es werden keine Produkte oder Angebote verkauft. Der Einkauf erfolgt
                im Geschäft des Anbieters.
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Vertrag – gibt es eine Mindestlaufzeit ?
              </p>
              <p>
                Grundsätzlich gibt es keine Mindestlaufzeit. Wenn alles gut läuft
                umso besser und Du darfst unsere Plattform solange nutzen, wie Du
                es möchtest. Das Angebot verlängert sich jedes Jahr automatisch mit
                der Zahlung des Mitgliederbeitrages. Die Mitgliedschaft kann mit
                einer Kündigungsfrist von 30 Tagen auf  Ablauf der Jahresfrist gekündet werden
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Verfügbarkeit der Produkte / Angebote mit Kalender angeben
              </p>
              <p>
                Beim erfassen deiner Produkte und Angebote kann du via der Kalenderfunktion
                angeben, wann bzw. wie lange sie verfügbar sind.
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Sind mein Name und Adresse öffentlich?
              </p>
              <p>
                Nein. Uns liegen Privatsphäre und Datenschutz genauso am Herzen wie
                dir. Daher veröffentlichen wir nie ohne deine Zustimmung persönlichen
                Informationen wie etwa deinen vollständigen Namen oder Adresse.
                Nach dem Du die Informationen zu deinem Profil auf Dorfplatz24
                hochgeladen hast, ist dieses zunächst nur für Nutzer sichtbar,
                denen Du den Direktlink zusendest. Wenn Du deine Produkte und Tische
                veröffentlichst, sind diese auf unserer Plattform für alle Nutzer
                zugänglich und es geht sehr schnell, bist Du kontaktiert wirst.
                Die Nutzer sehen die Informationen der Produkte und Tische sowie
                den Standort. Du kannst entscheiden, ob du einen ungefähren oder
                den genauen Standort angeben möchtest.
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Profil - Brauche ich ein Profilbild und Profilbeschreibung?
              </p>
              <p>
                Wir empfehlen dir, ein sympathisches Bild von dir als Profilbild
                hochzuladen und eine kurze Beschreibung über dich als Person und
                deinen Betrieb zu hinterlegen. Das macht dein Profil für andere
                Nutzer noch interessanter und weckt Vertrauen. Vollständig ausgefüllte
                Profile haben bei uns durchschnittlich 43% bessere Erfolgschancen.
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Muss ich ein Bankkonto hinterlegen?
              </p>
              <p>
                Nein. Damit Du Dorfplatz24 nutzen kannst, musst Du kein Bankkonto
                hinterlegen.
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Wer/Was ist Dorfplatz24
              </p>
              <p>
                Du fragst dich, wie du einfach Produkte und Angebote in deiner
                Region findest. Wir uns auch. Dorfplatz24 bringt Gewerbe und
                Konsumenten zusammen.
                <br/><br/>
                Entdecke lokale Produkte, Angebote und Dienstleistungen
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Dorfplatz24 ist der erste Schweizer Online-Katalog für lokale Produkte
                und Angebote mit Umgebungssuche und Kalenderfunktion.
              </p>
              <p>
                Wir leisten einen Beitrag zur besseren Vermarktung des lokalen
                Gewerbes und zur Attraktivität des Dorfes.
                <br/><br/>
                Zusammen vernetzen wir Konsument*innen und Gewerbetreibende.
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Wie viel kostet Dorfplatz24?
              </p>
              <p>
                Jahresmitgliedschaft: siehe Blog – Preise Mitgliedschaft
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Was bietet Dir Dorfplatz24?
              </p>
              <p>
                Wir sorgen dafür, dass du für deine Produkte und Angebote ideale
                Konsumenten/Kunden findest, die dein Angebot schätzen. Dabei kannst
                Du alles über unsere Plattform abwickeln - von der Ausschreibung
                über Chat-Funktion bis hin zum gemeinsamen Terminkalender ist alles
                dabei, was Du brauchst und Du hast jederzeit den vollen Zugriff darauf,
                egal wo Du gerade bist.<br/>
                Wir sind für das Marketing, die Sicherheit der Plattform und den
                Kundenservice zuständig. Wir sind ein junges, dynamisches und motiviertes
                Team und stecken unser Herzblut in diese Plattform. Für ein lebendiges
                und attraktives Dorfleben.
              </p>
            </div>
          </div>
          <br/>
          <h2 className={css.pageTitle}>FAQ Konsumenten/Kunden</h2>
          <h3 className={css.pageTitle}>Hier findest Du häufig gestellte Fragen von Reitern.</h3>
          <h3 className={css.pageTitle}>Bei dringlichen Fragen, kontaktiere uns gerne.</h3>
          <br/>
          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p style={{fontWeight: 'bold'}}>
                Was sind die Vorteile von Dorfplatz24?
              </p>
              <p>
                Unsere einfache, intuitive Plattform ist für dich als Konsument
                ein nützliches Tool, um für dich die spannende Produkte und Angebote
                in deiner Umgebung zu finden und dir das Leben einfacher zu machen.
                <br/><br/>
                Dank unserem grossen Angebot, der präzisen Suche und Filterfunktion,
                kannst Du in deiner direkten Umgebung duzende Produkte und Tische
                finden, die zu dir passen.
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Was kostet mich Dorfplatz24?
              </p>
              <p>
                Die Benutzung der Plattform für Konsumenten/Kunden ist gratis.
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Wieso registrieren?
              </p>
              <p>
                Als registrierter*in Nutzer kannst du die Chat-Funktion für Fragen
                oder Bestellungen an den Produzenten nutzen sowie die Funktion
                Favoritenliste für Produkte und Angebote nutzen, die du dir merken möchtest.
                <br/><br/>
                Die App kann auch ohne Registrierung genutzt für die Produktesuche.
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Profil - Brauche ich ein Profilbild und Profilbeschreibung?
              </p>
              <p>
                Wir empfehlen dir, ein sympathisches Bild von dir als Profilbild
                hochzuladen und eine kurze Beschreibung über dich als Person und
                deinen Betrieb zu hinterlegen. Das macht dein Profil für andere
                Nutzer noch interessanter und weckt Vertrauen. Vollständig ausgefüllte
                Profile haben bei uns durchschnittlich 43% bessere Erfolgschancen.
                <br/><br/>
                Im Profil musst du nicht deinen richtigen Namen angeben sondern
                kannst einen Nickname verwenden. Es benötigt auch keine genaue
                Adressangabe. Du kannst wählen, ob du deine Adresse angeben möchtest
                oder eine ungefähre Angabe im Ort.
              </p>
              <br/>
              <p style={{fontWeight: 'bold'}}>
                Dorfplatz24 ist der erste Schweizer Online-Katalog für lokale
                Produkte und Angebote mit Umgebungssuche und Kalenderfunktion.
              </p>
              <p>
                Wir leisten einen Beitrag zur besseren Vermarktung des lokalen
                Gewerbes und zur Attraktivität des Dorfes.
                <br/><br/>
                Zusammen vernetzen wir Konsument*innen und Gewerbetreibende.
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default FaqPage;
