import unionWith from 'lodash/unionWith';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { formatDateStringToUTC, getExclusiveEndDate } from '../../util/dates';
import config from '../../config';
import { denormalisedResponseEntities } from '../../util/data';

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

export const FETCH_SPONSOR_LISTINGS_REQUEST = 'app/SearchPage/FETCH_SPONSOR_LISTINGS_REQUEST';
export const FETCH_SPONSOR_LISTINGS_SUCCESS = 'app/SearchPage/FETCH_SPONSOR_LISTINGS_SUCCESS';
export const FETCH_SPONSOR_LISTINGS_ERROR = 'app/SearchPage/FETCH_SPONSOR_LISTINGS_ERROR';

export const CALCULATE_SPONSOR_INDEXES = 'app/SearchPage/CALCULATE_SPONSOR_INDEXES';

// ================ Helpers =================//

const pickSponsorListingIds = (listings, sponsorListings) => {
  const selectedSponsorListingIds = [];

  if(Array.isArray(listings) && listings.length > 0 &&
    Array.isArray(sponsorListings) && sponsorListings.length > 0)
  {
    const firstSponsorIndex = Math.floor(Math.random() * sponsorListings.length);

    selectedSponsorListingIds.push(firstSponsorIndex);

    if(sponsorListings.length > 1 && listings.length > 10){
      let secondSponsorIndex;

      do {
        secondSponsorIndex = Math.floor(Math.random() * sponsorListings.length);

        if (secondSponsorIndex !== firstSponsorIndex) {
          selectedSponsorListingIds.push(secondSponsorIndex);
        }
      } while(secondSponsorIndex === firstSponsorIndex);
    }
  }

  return selectedSponsorListingIds;
};

const pickListingIds = listings => {
  const selectedListingIds = [];

  if(Array.isArray(listings) && listings.length > 0){
    const firstListingId = Math.floor(Math.random() * Math.min(listings.length, 10));

    selectedListingIds.push(firstListingId);

    if(listings.length > 10){
      const secondListingId =
        Math.floor(Math.random() * (Math.min(listings.length, 20) - 10)) + 10;

      selectedListingIds.push(secondListingId);
    }
  }

  return selectedListingIds;
};

const constructSponsorIndexes = (listings, sponsorListings) => {
  const selectedSponsorListingIds = pickSponsorListingIds(listings, sponsorListings);
  const selectedListingIds = pickListingIds(listings);
  const sponsorIndexes = [];

  if(selectedSponsorListingIds.length > 0 && selectedListingIds.length > 0){
    sponsorIndexes.push({
      listingInd: selectedListingIds[0],
      sponsorInd: selectedSponsorListingIds[0]
    });

    if(selectedSponsorListingIds.length > 1 && selectedListingIds.length > 1){
      sponsorIndexes.push({
        listingInd: selectedListingIds[1],
        sponsorInd: selectedSponsorListingIds[1]
      });
    }
  }

  return sponsorIndexes;
};

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingIds: [],
  searchMapListingsError: null,
  sponsorListings: null,
  sponsorListingIds: [],
  fetchSponsorListingsInProgress: false,
  fetchSponsorListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }
    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    case FETCH_SPONSOR_LISTINGS_REQUEST:
      return {
        ...state,
        fetchSponsorListingsInProgress: true,
        fetchSponsorListingsError: null,
      };
    case FETCH_SPONSOR_LISTINGS_SUCCESS: {
      const sponsorListings = payload.entities;

      return {
        ...state,
        sponsorListings,
        fetchSponsorListingsInProgress: false,
      };
    }
    case FETCH_SPONSOR_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchSponsorListingsError: payload };

    case CALCULATE_SPONSOR_INDEXES:
      const sponsorListings = state.sponsorListings;
      const listings = payload.listings;
      const sponsorListingIds = constructSponsorIndexes(listings, sponsorListings);

      return { ...state, sponsorListingIds };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const fetchSponsorListingsRequest = () => ({
  type: FETCH_SPONSOR_LISTINGS_REQUEST,
  payload: {},
});

export const fetchSponsorListingsSuccess = entities => ({
  type: FETCH_SPONSOR_LISTINGS_SUCCESS,
  payload: { entities },
});

export const fetchSponsorListingsError = e => ({
  type: FETCH_SPONSOR_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const calculateSponsorIndexes = listings => ({
  type: CALCULATE_SPONSOR_INDEXES,
  payload: { listings },
});

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));

  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };

  const datesSearchParams = datesParam => {
    const values = datesParam ? datesParam.split(',') : [];
    const hasValues = datesParam && values.length === 2;
    const startDate = hasValues ? values[0] : null;
    const isNightlyBooking = config.bookingUnitType === 'line-item/night';
    const endDate =
      hasValues && isNightlyBooking ? values[1] : hasValues ? getExclusiveEndDate(values[1]) : null;

    return hasValues
      ? {
          start: formatDateStringToUTC(startDate),
          end: formatDateStringToUTC(endDate),
          // Availability can be full or partial. Default value is full.
          availability: 'full',
        }
      : {};
  };

  const { perPage, price, dates, ...rest } = searchParams;
  const priceMaybe = priceSearchParams(price);
  const datesMaybe = datesSearchParams(dates);

  if(rest.pub_category){
    rest.pub_categoryKeys = `has_any:${rest.pub_category}`;
    delete rest.pub_category;
  }

  if(!rest.pub_type){
    rest.pub_type = `table,product`;
  }

  const params = {
    ...rest,
    ...priceMaybe,
    ...datesMaybe,
    per_page: perPage,
  };

  return dispatch(fetchSponsorListings(searchParams.bounds))
    .then(() => {
      return sdk.listings.query(params);
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(response));
      dispatch(calculateSponsorIndexes(response.data.data));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;

  if(rest.pub_category){
    rest.pub_categoryKeys = `has_any:${rest.pub_category}`;
    delete rest.pub_category;
  }

  if(!rest.pub_type){
    rest.pub_type = `table,product`;
  }

  const params = {
    ...rest,
    per_page: perPage,
  };

  return dispatch(fetchSponsorListings(searchParams.bounds))
    .then(() => {
      return sdk.listings.query(params);
    })
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      dispatch(calculateSponsorIndexes(response.data.data));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const fetchSponsorListings = bounds => (dispatch, getState, sdk) => {
  dispatch(fetchSponsorListingsRequest());

  const params = {
    pub_type: 'sponsor',
    per_page: 100,
    include: ['images'],
    'fields.image': [
      'variants.landscape-crop',
      'variants.landscape-crop2x',
      'variants.square-small',
      'variants.square-small2x'
    ],
    bounds,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      const entities = denormalisedResponseEntities(response);

      dispatch(fetchSponsorListingsSuccess(entities));
      return response;
    })
    .catch(e => {
      dispatch(fetchSponsorListingsError(storableError(e)));
      throw e;
    });
};
