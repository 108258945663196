import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.css';

const SectionExpiryMaybe = props => {
  const { validFromDate, expiryDate } = props;

  const validFrom = new Date( validFromDate );
  const vd = String( validFrom.getDate()).padStart(2, '0');
  const vm = String( validFrom.getMonth() + 1).padStart(2, '0');
  const vy = validFrom.getFullYear();
  const validFromFormattedDate = `${vd}.${vm}.${vy}`;

  const expiry = new Date( expiryDate );
  const ed = String( expiry.getDate()).padStart(2, '0');
  const em = String( expiry.getMonth() + 1).padStart(2, '0');
  const ey = expiry.getFullYear();
  const expiryFormattedDate = `${ed}.${em}.${ey}`;

  return validFromDate || expiryDate ? (
    <div className={css.sectionDescription}>
      <h2 className={css.descriptionTitle}>
        <FormattedMessage id="ListingPage.expiryTitle" />
      </h2>
      { validFromDate ? (
        <p className={css.expiryDate}>
          <div className={css.expiryLabel}>
            <FormattedMessage id="ListingPage.validFromDateTitle" />
          </div>
          &nbsp;&nbsp;&nbsp;{validFromFormattedDate}
        </p>
      ) : null }
      { expiryDate ? (
        <p className={css.expiryDate}>
          <div className={css.expiryLabel}>
            <FormattedMessage id="ListingPage.expiryDateTitle" />
          </div>
          &nbsp;&nbsp;&nbsp;{expiryFormattedDate}
        </p>
      ) : null }
    </div>
  ) : null;
};

export default SectionExpiryMaybe;
