import React from 'react';
import PropTypes from 'prop-types';

const FullHeartIcon = props => {
  const { className, ...rest } = props;

  return (
    <svg
      className={className}
      {...rest}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m29.461 2.786c-1.656-1.797-3.929-2.786-6.401-2.786-1.847 0-3.539.584-5.028 1.736-.751.581-1.432 1.292-2.033 2.123-.6-.83-1.281-1.541-2.033-2.123-1.489-1.152-3.181-1.736-5.028-1.736-2.471 0-4.744.99-6.401 2.786-1.637 1.776-2.538 4.201-2.538 6.831 0 2.706 1.009 5.184 3.174 7.796 1.937 2.337 4.721 4.71 7.945 7.457 1.101.938 2.349 2.002 3.644 3.135.342 .3.781 .465 1.237.465 .455 0 .895-.165 1.237-.464 1.296-1.133 2.544-2.197 3.646-3.136 3.223-2.747 6.007-5.119 7.944-7.457 2.165-2.613 3.174-5.09 3.174-7.796 0-2.629-.902-5.055-2.539-6.831zm0 0"
        fillRule="evenodd"
      />
    </svg>
  );
};

const { string } = PropTypes;

FullHeartIcon.defaultProps = {
  className: null,
};

FullHeartIcon.propTypes = {
  className: string,
};

export default FullHeartIcon;
