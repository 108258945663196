import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './Impressum.css';

const Impressum = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>
        gut und frisch GmbH<br/>
        Im Russer 5c<br/>
        8708 Männedorf<br/>
        Schweiz<br/><br/>

        Vertreten durch: Matthias Steiger<br/>
        Sitz der Gesellschaft: Männedorf, Schweiz<br/><br/>

        Handelsregistereintrag (Handelsregister Kanton Zürich, Schweiz): CHE-202.751.242<br/>
        Telefon: +41 79 819 15 52<br/>
        E-Mail: info@dorfplatz24.ch<br/>
      </p>

      <h2>Haftungsausschluss</h2>
      <p>
        Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen
        Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit
        der Informationen.<br/>
        Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller
        Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten
        Informationen, durch Missbrauch der Verbindung oder durch technische Störungen
        entstanden sind, werden ausgeschlossen.<br/>
        Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor,
        Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu
        verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder
        endgültig einzustellen.<br/>
      </p>

      <h2>Haftung für Links</h2>
      <p>
        Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs
        Es wird jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff
        und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des Nutzers
        oder der Nutzerin.
      </p>

      <h2>Urheberrechte</h2>
      <p>
        Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos oder anderen
        Dateien auf der Website gehören ausschliesslichder Firma gut und frisch GmbH
        oder den speziell genannten Rechtsinhabern. Für die Reproduktion jeglicher
        Elemente ist die schriftliche Zustimmung der Urheberrechtsträger im Voraus
        einzuholen.
      </p>

      <h2>Datenschutzerklärung für die Nutzung von Google Analytics</h2>
      <p>
        Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc.
        (“Google”). Google Analytics verwendet sog. “Cookies”, Textdateien, die
        auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung
        der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen
        über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google
        in den USA übertragen und dort gespeichert. Im Falle der Aktivierung der
        IP-Anonymisierung auf dieser Webseite wird Ihre IP-Adresse von Google jedoch
        innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
        des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt.<br/><br/>

        Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
        in den USA übertragen und dort gekürzt. Google wird diese Informationen
        benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die
        Websiteaktivitäten für die Websitebetreiber zusammenzustellen und um weitere
        mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte
        übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese
        Daten im Auftrag von Google verarbeiten. Die im Rahmen von Google Analytics
        von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
        Google zusammengeführt.<br/><br/>

        Sie können die Installation der Cookies durch eine entsprechende Einstellung
        Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass
        Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website
        voll umfänglich nutzen können. Durch die Nutzung dieser Website erklären Sie
        sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der
        zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.
      </p>
    </div>
  );
};

Impressum.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

Impressum.propTypes = {
  rootClassName: string,
  className: string,
};

export default Impressum;
