import React from 'react';
import config from '../../config';
import { twitterPageURL } from '../../util/urlHelpers';
import { StaticPage, TopbarContainer } from '../../containers';
import {
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';

import css from './AboutPage.css';
import image1 from './about-us-1.jpg';
import konsumentImage from './konsument.png';
import gewerbeImage from './gewerbe.png';
import werbungImage from './werbung.png';

const AboutPage = () => {
  // prettier-ignore
  return (
    <StaticPage
      title="About Us"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'AboutPage',
        description: 'About Dorfplatz24',
        name: 'About page',
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain className={css.staticPageWrapper}>
          <h3 className={css.pageSubheading}>
            Dorfplatz24.ch – mit allen Vorteilen der Online-Welt: Geschwindigkeit,<br/>
            Aktualität, 24/7-Zugang von überall.
          </h3>

          <h1 className={css.pageTitle}>Dorfplatz24.ch</h1>

          <div className={css.contentWrapper}>
            <div className={css.contentMain}>
              <p className={css.contentSubtitle}>
                Den Austausch zwischen Gewerbe und Konsumenten fördern.
              </p>

              <h2>
                In einer Welt, die zunehmend anonym ist und wo das Lokalgewerbe
                in Städten und Dörfern durch grosse Bewerber aus dem Markt gedrängt
                wird, ist es unser Wunsch, dass wir, das Gewerbe und die Konsumenten,
                bedacht Direktkontakt miteinander pflegen. Sympathische Nähe.
                Persönlicher Austausch.
              </h2>

              <p>
                Das Gewerbe vermarktet seine Produkte auf einfachste Art und Weise online.<br/>
                Bestellen kann man diese nicht online.
              </p>

              <p>
                Eingekauft wird im Geschäft, im Dorf.
              </p>

              <p>
                Das Dorf darf Dorf bleiben.
              </p>

              <p>
                Wir verknüpfen Dorfläden, Gewerbe, Dienstleister und lokale Produzenten
                mit der lokalen Kundschaft und stellen so sicher, dass wertvolle
                regionale Angebote bekannter, mehr geschätzt und häufiger gekauft werden. Vor Ort.
              </p>

              <img className={css.coverImage} src={konsumentImage} alt="Konsument" />

              <h2>
                Konsument
              </h2>

              <p>
                Du möchtest:
                <ul className={css.diskList}>
                  <li>das lokale Gewerbe unterstützen?</li>
                  <li>nachhaltigeren Konsum in Deiner Gemeinde fördern?</li>
                  <li>für ein lebendiges und attraktives Dorfleben einstehen?</li>
                </ul>
              </p>

              <p>
                Hier kannst Du jederzeit nachschauen, was lokale Dorfläden, Gewerbe
                und Dienstleister zu bieten haben, vom Normalsortiment über Neuigkeiten
                zu Aktionen. Sie entscheiden, was sie anbieten möchten und bewirtschaften
                ihre Beiträge selbst.
              </p>

              <p>
                Dorfplatz24.ch ist für Dich als Konsument kostenlos.
              </p>

              <h3 className={css.pageSubheading}>
                Dorfplatz24.ch – mit allen Vorteilen der Online-Welt: Geschwindigkeit,
                Aktualität, 24/7-Zugang von überall.
              </h3>

              <h2 id="gewerbe">
                Gewerbe
              </h2>

              <p>
                Du möchtest:
                <ul className={css.diskList}>
                  <li>Angebot/Neuigkeiten/Aktionen übers Internet veröffentlichen?</li>
                  <li>Deine angebotenen Produkte stets zeitnah selbst bewirtschaften?</li>
                  <li>über unsere benutzerfreundliche Website nahe am Kunden sein?</li>
                </ul>
              </p>

              <p>
                Wir stellen Dir unser Internet-Portal zur Verfügung, über welches
                Du eigenständig Deine Produkte bewirbst. Je interessanter Deine Auswahl,
                desto mehr werden Deine Kunden Dein Gewerbe online aufsuchen – und
                vor Ort bei Dir einkaufen.
              </p>

              <p>
                Dorfplatz24.ch bietet Dir folgende Abonnements an:
                <ul className={css.diskList}>
                  <li>1 Monat gratis: risikoloses Ausprobieren</li>
                  <li>6 Monate zu total CHF 90.-</li>
                  <li>1 Jahr zu total CHF 160.-</li>
                </ul>
              </p>

              <img className={css.coverImage} src={gewerbeImage} alt="Gewerbe" />

              <h2 id="wie-funktioniert">
                Wie’s funktioniert
              </h2>

              <p>
                Die Web App, die Dein Angebot näher an den Konsumenten bringt.<br/>

                Dorfplatz24 bringt Gewerbe und Konsumenten zusammen.<br/>
                Die Web App für die Förderung von mehr Konsum im eigenen Dorf<br/>

                <ul className={css.diskList}>
                  <li>Mit Dorfplatz24 werden Eure Angebote für die Bevölkerung sichtbar.</li>
                  <li>Die Web App kann von Euch selbst einfach bewirtschaftet werden.</li>
                  <li>Du erhältst Sticker mit QR-Codes für Werbung an Türen/Schaufenster/Auto.</li>
                  <li>Ihr vermarktet eure Produkte auf einfachste Art und Weise online.</li>
                  <li>Bestellen kann man diese nicht online.</li>
                  <li>Eingekauft wird im Geschäft, im Dorf.</li>
                  <li>Du machst die Produktefotos selbst (ideal Querformat) und lädst sie mit den dazugehörigen Informationen hoch.</li>
                </ul>

                Wie gehst du vor:

                <ul className={css.diskList}>
                  <li>Registrieren</li>
                  <li>Eigene Fotos von Produkten machen (ideal Querformat)</li>
                  <li>Einloggen – Produkte wählen</li>
                  <li>Titel des Angebotes oder Produktname einfügen</li>
                  <li>Dein Produkt oder Angebot beschreiben</li>
                  <li>Kategorie wählen – Art des Produktes</li>
                  <li>Art der Einheit wählen: z.B. pro Stück, pro Kilo...</li>
                  <li>Preis angeben
                    (bei gewissen Angeboten ist die Erfassung auch ohne Preisangabe möglich)</li>
                  <li>Angabe wie lange ist das Angebot gültig (danach ist das Inserat nicht mehr sichtbar)</li>
                  <li>Verfügbarkeit angeben</li>
                  <li>Standort, Firmenname, Adresse, Link zur Webseite, Telefonnummer angeben</li>
                  <li>Details/Saison angeben</li>
                  <li>Fotos hochladen</li>
                </ul>
                <br/><br/>
                und dann den Eintrag veröffentlichen
                <br/><br/>
                <b>Kosten:</b>

                <ul className={css.diskList}>
                  <li>1 Monat gratis: risikoloses Ausprobieren</li>
                  <li>6 Monate zu total CHF 90.-</li>
                  <li>1 Jahr zu total CHF 160.-</li>
                </ul>
                Jahresmitgliedschaft: CHF 15 pro Monat = 12 * 15 = CHF 180 / CHF 160 jährlich bezahlt
                <br/>
                Monatliche Mitgliedschaft: CHF 20 / monatlich bezahlt
              </p>

              <h3 className={css.pageSubheading}>
                Dorfplatz24.ch – mit allen Vorteilen der Online-Welt: Geschwindigkeit,
                Aktualität, 24/7-Zugang von überall.
              </h3>

              <h2 id="werbung">
                Werbung & Sponsoring pro Gemeinde
              </h2>

              <p>
                <ul className={css.diskList}>
                  <li>Ihr möchtet euren Werbe-Banner im lokalen Umfeld setzen?</li>
                  <li>Die Sicherheit haben, dass es praktisch keinen Streuverlust gibt?</li>
                  <li>Von diversen Sponsoring-Varianten profitieren?</li>
                  <li>Dorfplatz24.ch bietet Dir folgende Möglichkeit an: Sponsor Plattform CHF 750.-.</li>
                  <li>Ihr erhaltet ein Sponsor-Inserat für Eure Gemeinde, das abwechselnd erscheint</li>
                  <li>Eure Firma wird während 365 Tagen als Sponsor genannt.</li>
                </ul>
              </p>

              <img className={css.coverImage} src={werbungImage} alt="Werbung" />

              <img className={css.coverImage} src={image1} alt="Über uns" />

              <h3 className={css.pageSubheading}>
                Dorfplatz24.ch – mit allen Vorteilen der Online-Welt: Geschwindigkeit,<br/>
                Aktualität, 24/7-Zugang von überall.
              </h3>

              <h3 id="contact" className={css.subtitle}>
                Kontakt:
              </h3>
              <p>
                Besuche uns auf Facebook und Instagram.<br/>
                Schreibe uns auf <span style={{ textDecoration: 'underline' }}>info@dorfplatz24.ch</span> und wir melden uns zeitnah bei dir. Vielen Dank
              </p>
            </div>
          </div>
        </LayoutWrapperMain>

        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </StaticPage>
  );
};

export default AboutPage;
