import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionTables.css';

import category4Image from './images/category4_deals_n_promotion.jpg';
import category5Image from './images/category5_wohnen_n_handwerk.jpg';
import category6Image from './images/category6_geschenke.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, translationId, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id={translationId}
          values={{ category: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionTables = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.locations}>
        {locationLink(
          'Deals & Promotion',
          category4Image,
          "SectionTables.labelCategory4",
          '?address=Schweiz&bounds=47.8084549866365%2C10.4920638273824%2C45.8180770356515%2C5.95599426104971&pub_category=deals_n_promotion'
        )}
        {locationLink(
          'Wohnen & Handwerk',
          category5Image,
          "SectionTables.labelCategory5",
          '?address=Schweiz&bounds=47.8084549866365%2C10.4920638273824%2C45.8180770356515%2C5.95599426104971&pub_category=wohnen_n_handwerk'
        )}
        {locationLink(
          'Geschenke',
          category6Image,
          "SectionTables.labelCategory6",
          '?address=Schweiz&bounds=47.8084549866365%2C10.4920638273824%2C45.8180770356515%2C5.95599426104971&pub_category=geschenke'
        )}
      </div>
    </div>
  );
};

SectionTables.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionTables.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionTables;
