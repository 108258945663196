export const findCategoryByKey = (categoryKey, categoryOptions) => {
  let category = null;

  if(categoryKey && Array.isArray(categoryOptions)) {
    categoryOptions.forEach(option => {
      if (option.key === categoryKey) {
        category = option;
      }
    });
  }

  return category;
};
