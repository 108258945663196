import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import css from './SectionInvestorPromo.css';

import investorLogoImage from './images/investor_logo.jpg';

class InvestorImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(InvestorImage);

const SectionInvestorPromo = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.investorPromoText}>
        <FormattedMessage id="SectionInvestorPromo.promoText" />
        <img src={investorLogoImage} alt="Investor Logo" className={css.logoImage} />
      </div>
    </div>
  );
};

SectionInvestorPromo.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionInvestorPromo.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionInvestorPromo;
