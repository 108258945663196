import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionHero.css';

const SectionHero = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  const fundProductsButton = (
    <NamedLink
      name="SearchPage"
      to={{
        search:
          'address=Schweiz&bounds=47.8084549866365%2C10.4920638273824%2C45.8180770356515%2C5.95599426104971&pub_type=product',
      }}
      className={css.heroButton}
    >
      <FormattedMessage id="SectionHero.browseButton" />
    </NamedLink>
  );

  const findTablesButton = null; /* (
    <NamedLink
      name="SearchPage"
      to={{
        search:
          'address=Schweiz&bounds=47.8084549866365%2C10.4920638273824%2C45.8180770356515%2C5.95599426104971&pub_type=table',
      }}
      className={css.heroButton}
    >
      <FormattedMessage id="SectionHero.browseButton2" />
    </NamedLink>
  );*/

  return (
    <div className={classes}>
      <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          <FormattedMessage id="SectionHero.title" />
        </h1>
        <h2 className={css.heroSubTitle}>
          <FormattedMessage id="SectionHero.subTitle" />
        </h2>
        {fundProductsButton}
        {findTablesButton}
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
