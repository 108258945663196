import axios from 'axios';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser } from '../../ducks/user.duck';

// ================ Action types ================ //

export const FINALIZE_STRIPE_SUBSCRIPTION_REQUEST = 'app/SubscriptionPage/FINALIZE_STRIPE_SUBSCRIPTION_REQUEST';
export const FINALIZE_STRIPE_SUBSCRIPTION_SUCCESS = 'app/SubscriptionPage/FINALIZE_STRIPE_SUBSCRIPTION_SUCCESS';
export const FINALIZE_STRIPE_SUBSCRIPTION_ERROR = 'app/SubscriptionPage/FINALIZE_STRIPE_SUBSCRIPTION_ERROR';
export const UPDATE_STRIPE_SUBSCRIPTION_REQUEST = 'app/SubscriptionPage/UPDATE_STRIPE_SUBSCRIPTION_REQUEST';
export const UPDATE_STRIPE_SUBSCRIPTION_SUCCESS = 'app/SubscriptionPage/UPDATE_STRIPE_SUBSCRIPTION_SUCCESS';
export const UPDATE_STRIPE_SUBSCRIPTION_ERROR = 'app/SubscriptionPage/UPDATE_STRIPE_SUBSCRIPTION_ERROR';
export const CANCEL_STRIPE_SUBSCRIPTION_REQUEST = 'app/SubscriptionPage/CANCEL_STRIPE_SUBSCRIPTION_REQUEST';
export const CANCEL_STRIPE_SUBSCRIPTION_SUCCESS = 'app/SubscriptionPage/CANCEL_STRIPE_SUBSCRIPTION_SUCCESS';
export const CANCEL_STRIPE_SUBSCRIPTION_ERROR = 'app/SubscriptionPage/CANCEL_STRIPE_SUBSCRIPTION_ERROR';


// ================ Reducer ================ //

const initialState = {
  stripeSubscriptionError: null,
  stripeSubscriptionInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FINALIZE_STRIPE_SUBSCRIPTION_REQUEST:
    case UPDATE_STRIPE_SUBSCRIPTION_REQUEST:
    case CANCEL_STRIPE_SUBSCRIPTION_REQUEST:
      return {
        ...state,
        stripeSubscriptionInProgress: true,
        stripeSubscriptionError: null,
      };
    case FINALIZE_STRIPE_SUBSCRIPTION_SUCCESS:
    case UPDATE_STRIPE_SUBSCRIPTION_SUCCESS:
    case CANCEL_STRIPE_SUBSCRIPTION_SUCCESS:
      return { ...state, stripeSubscriptionInProgress: false };
    case FINALIZE_STRIPE_SUBSCRIPTION_ERROR:
    case UPDATE_STRIPE_SUBSCRIPTION_ERROR:
    case CANCEL_STRIPE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        stripeSubscriptionInProgress: false,
        stripeSubscriptionError: payload
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const finalizeStripeSubscriptionRequest = () => ({ type: FINALIZE_STRIPE_SUBSCRIPTION_REQUEST });
export const finalizeStripeSubscriptionSuccess = () => ({ type: FINALIZE_STRIPE_SUBSCRIPTION_SUCCESS });
export const finalizeStripeSubscriptionError = error => ({
  type: FINALIZE_STRIPE_SUBSCRIPTION_ERROR,
  payload: error,
});
export const updateStripeSubscriptionRequest = () => ({ type: UPDATE_STRIPE_SUBSCRIPTION_REQUEST });
export const updateStripeSubscriptionSuccess = () => ({ type: UPDATE_STRIPE_SUBSCRIPTION_SUCCESS });
export const updateStripeSubscriptionError = error => ({
  type: UPDATE_STRIPE_SUBSCRIPTION_ERROR,
  payload: error,
});
export const cancelStripeSubscriptionRequest = () => ({ type: CANCEL_STRIPE_SUBSCRIPTION_REQUEST });
export const cancelStripeSubscriptionSuccess = () => ({ type: CANCEL_STRIPE_SUBSCRIPTION_SUCCESS });
export const cancelStripeSubscriptionError = error => ({
  type: CANCEL_STRIPE_SUBSCRIPTION_ERROR,
  payload: error,
});

// ================ Thunks ================ //

export const finalizeStripeSubscription = stripeSubscriptionKey => (dispatch, getState, sdk) => {
  dispatch(finalizeStripeSubscriptionRequest());

  return sdk.currentUser
    .updateProfile(
      { privateData: { stripeSubscriptionKey } },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(response => {
      const entities = denormalisedResponseEntities(response);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }

      dispatch(finalizeStripeSubscriptionSuccess());

      return dispatch(fetchCurrentUser());
    })
    .catch(e => {
      console.error('Error finalizing Stripe subscription procedure: ' + e.message);
      dispatch(finalizeStripeSubscriptionError(storableError(e)));
    });
};

export const updateStripeSubscription = (userId, newStripePriceId, newStripeSubscriptionKey) => (dispatch, getState, sdk) =>
{
  dispatch(updateStripeSubscriptionRequest());

  return axios({
    url: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/api/update-stripe-subscription`,
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    data: JSON.stringify({ userId, newStripePriceId, newStripeSubscriptionKey }),
  })
    .then(() => {
      dispatch(updateStripeSubscriptionSuccess());

      return dispatch(fetchCurrentUser());
    })
    .catch(error => {
      console.error('Error updating existing Stripe subscription : ' + error.message);
      dispatch(updateStripeSubscriptionError(storableError(error)));
    });
};

export const cancelStripeSubscription = userId => (dispatch, getState, sdk) => {
  dispatch(cancelStripeSubscriptionRequest());

  return axios({
    url: `${process.env.REACT_APP_CANONICAL_ROOT_URL}/api/cancel-stripe-subscription`,
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    data: JSON.stringify({ userId }),
  })
    .then(() => {
      dispatch(cancelStripeSubscriptionSuccess());

      return dispatch(fetchCurrentUser());
    })
    .catch(error => {
      console.error('Error cancelling existing Stripe subscription : ' + error.message);
      dispatch(cancelStripeSubscriptionError(storableError(error)));
    });
};
