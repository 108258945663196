import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import * as validators from '../../util/validators';
import {
  Form, PrimaryButton, FieldTextInput, FieldPhoneNumberInput, FieldSelect
} from '../../components';

import css from './SignupForm.css';

const KEY_CODE_ENTER = 13;

const SignupFormComponent = props => (
  <FinalForm
    {...props}
    render={fieldRenderProps => {
      const {
        rootClassName,
        className,
        formId,
        values,
        handleSubmit,
        inProgress,
        invalid,
        intl,
        onOpenTermsOfService,
      } = fieldRenderProps;

      // email
      const emailLabel = intl.formatMessage({
        id: 'SignupForm.emailLabel',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = validators.required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = validators.emailFormatValid(emailInvalidMessage);

      // password
      const passwordLabel = intl.formatMessage({
        id: 'SignupForm.passwordLabel',
      });
      const passwordPlaceholder = intl.formatMessage({
        id: 'SignupForm.passwordPlaceholder',
      });
      const passwordRequiredMessage = intl.formatMessage({
        id: 'SignupForm.passwordRequired',
      });
      const passwordMinLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooShort',
        },
        {
          minLength: validators.PASSWORD_MIN_LENGTH,
        }
      );
      const passwordMaxLengthMessage = intl.formatMessage(
        {
          id: 'SignupForm.passwordTooLong',
        },
        {
          maxLength: validators.PASSWORD_MAX_LENGTH,
        }
      );
      const passwordMinLength = validators.minLength(
        passwordMinLengthMessage,
        validators.PASSWORD_MIN_LENGTH
      );
      const passwordMaxLength = validators.maxLength(
        passwordMaxLengthMessage,
        validators.PASSWORD_MAX_LENGTH
      );
      const passwordRequired = validators.requiredStringNoTrim(passwordRequiredMessage);
      const passwordValidators = validators.composeValidators(
        passwordRequired,
        passwordMinLength,
        passwordMaxLength
      );

      // firstName
      const firstNameLabel = intl.formatMessage({
        id: 'SignupForm.firstNameLabel',
      });
      const firstNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.firstNamePlaceholder',
      });
      const firstNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.firstNameRequired',
      });
      const firstNameRequired = validators.required(firstNameRequiredMessage);

      // lastName
      const lastNameLabel = intl.formatMessage({
        id: 'SignupForm.lastNameLabel',
      });
      const lastNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.lastNamePlaceholder',
      });
      const lastNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.lastNameRequired',
      });
      const lastNameRequired = validators.required(lastNameRequiredMessage);

      // User Role
      const userRoleLabel = intl.formatMessage({
        id: 'SignupForm.userRoleLabel',
      });
      const userRolePlaceholder = intl.formatMessage({
        id: 'SignupForm.userRolePlaceholder',
      });
      const userRoleCustomerOption = intl.formatMessage({
        id: 'SignupForm.userRoleCustomerOption',
      });
      const userRoleProviderOption = intl.formatMessage({
        id: 'SignupForm.userRoleProviderOption',
      });
      const userRoleRequiredMessage = intl.formatMessage({
        id: 'SignupForm.userRoleRequired',
      });
      const userRoleRequired = validators.required(userRoleRequiredMessage);

      // Company Name
      const companyNameLabel = intl.formatMessage({
        id: 'SignupForm.providerCompanyNameLabel',
      });
      const companyNamePlaceholder = intl.formatMessage({
        id: 'SignupForm.providerCompanyNamePlaceholder',
      });
      const companyNameRequiredMessage = intl.formatMessage({
        id: 'SignupForm.providerCompanyNameRequired',
      });
      const companyNameRequired = validators.required(companyNameRequiredMessage);

      // Company Address
      const companyAddressLabel = intl.formatMessage({
        id: 'SignupForm.providerAddressLabel',
      });
      const companyAddressPlaceholder = intl.formatMessage({
        id: 'SignupForm.providerAddressPlaceholder',
      });
      const companyAddressRequiredMessage = intl.formatMessage({
        id: 'SignupForm.providerAddressRequired',
      });
      const companyAddressRequired = validators.required(companyAddressRequiredMessage);

      // Phone Number
      const providerPhoneNumberLabel = intl.formatMessage({
        id: 'SignupForm.providerPhoneNumberLabel',
      });
      const providerPhoneNumberPlaceholder = intl.formatMessage({
        id: 'SignupForm.providerPhoneNumberPlaceholder',
      });
      const providerPhoneNumberRequiredMessage = intl.formatMessage({
        id: 'SignupForm.providerPhoneNumberRequired',
      });
      const providerPhoneNumberRequired = validators.required(providerPhoneNumberRequiredMessage);

      // Company Email
      const providerCompanyEmailLabel = intl.formatMessage({
        id: 'SignupForm.providerCompanyEmailLabel',
      });
      const providerCompanyEmailPlaceholder = intl.formatMessage({
        id: 'SignupForm.providerCompanyEmailPlaceholder',
      });
      const providerCompanyEmailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.providerCompanyEmailRequired',
      });
      const providerCompanyEmailRequired = validators.required(providerCompanyEmailRequiredMessage);
      const providerCompanyEmailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.providerCompanyEmailInvalid',
      });
      const providerCompanyEmailInvalid = validators.emailFormatValid(providerCompanyEmailInvalidMessage);

      const classes = classNames(rootClassName || css.root, className);
      const submitInProgress = inProgress;
      const submitDisabled = invalid || submitInProgress;

      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          onOpenTermsOfService();
        }
      };
      const termsLink = (
        <span
          className={css.termsLink}
          onClick={onOpenTermsOfService}
          role="button"
          tabIndex="0"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="SignupForm.termsAndConditionsLinkText" />
        </span>
      );

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          <div>
            <FieldTextInput
              type="email"
              id={formId ? `${formId}.email` : 'email'}
              name="email"
              autoComplete="email"
              label={emailLabel}
              placeholder={emailPlaceholder}
              validate={validators.composeValidators(emailRequired, emailValid)}
            />
            <div className={css.name}>
              <FieldTextInput
                className={css.firstNameRoot}
                type="text"
                id={formId ? `${formId}.fname` : 'fname'}
                name="fname"
                autoComplete="given-name"
                label={firstNameLabel}
                placeholder={firstNamePlaceholder}
                validate={firstNameRequired}
              />
              <FieldTextInput
                className={css.lastNameRoot}
                type="text"
                id={formId ? `${formId}.lname` : 'lname'}
                name="lname"
                autoComplete="family-name"
                label={lastNameLabel}
                placeholder={lastNamePlaceholder}
                validate={lastNameRequired}
              />
            </div>
            <FieldTextInput
              className={css.password}
              type="password"
              id={formId ? `${formId}.password` : 'password'}
              name="password"
              autoComplete="new-password"
              label={passwordLabel}
              placeholder={passwordPlaceholder}
              validate={passwordValidators}
            />

            <FieldSelect
              id="userRole"
              name="userRole"
              className={css.userRole}
              label={userRoleLabel}
              validate={userRoleRequired}
            >
              <option disabled value="">
                {userRolePlaceholder}
              </option>
              <option key="customer-role-option" value="customer">
                {userRoleCustomerOption}
              </option>
              <option key="provider-role-option" value="provider">
                {userRoleProviderOption}
              </option>
            </FieldSelect>

            { values.userRole === 'provider' ? (
              <>
                <FieldTextInput
                  className={css.companyName}
                  type="text"
                  id={formId ? `${formId}.companyName` : 'companyName'}
                  name="companyName"
                  autoComplete="organization"
                  label={companyNameLabel}
                  placeholder={companyNamePlaceholder}
                  validate={companyNameRequired}
                />
                <FieldTextInput
                  className={css.companyAddress}
                  type="text"
                  id={formId ? `${formId}.companyAddress` : 'companyAddress'}
                  name="companyAddress"
                  autoComplete="street-address"
                  label={companyAddressLabel}
                  placeholder={companyAddressPlaceholder}
                  validate={companyAddressRequired}
                />
                <FieldPhoneNumberInput
                  className={css.phoneNumber}
                  name="phoneNumber"
                  id={formId ? `${formId}.phoneNumber` : 'phoneNumber'}
                  label={providerPhoneNumberLabel}
                  placeholder={providerPhoneNumberPlaceholder}
                />
                <FieldTextInput
                  className={css.companyEmail}
                  type="companyEmail"
                  id={formId ? `${formId}.companyEmail` : 'companyEmail'}
                  name="companyEmail"
                  autoComplete="email"
                  label={providerCompanyEmailLabel}
                  placeholder={providerCompanyEmailPlaceholder}
                  validate={validators.composeValidators(providerCompanyEmailRequired, providerCompanyEmailInvalid)}
                />

              </>
            ) : null }
          </div>

          <div className={css.bottomWrapper}>
            <p className={css.bottomWrapperText}>
              <span className={css.termsText}>
                <FormattedMessage
                  id="SignupForm.termsAndConditionsAcceptText"
                  values={{ termsLink }}
                />
              </span>
            </p>
            <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
              <FormattedMessage id="SignupForm.signUp" />
            </PrimaryButton>
          </div>
        </Form>
      );
    }}
  />
);

SignupFormComponent.defaultProps = { inProgress: false };

const { bool, func } = PropTypes;

SignupFormComponent.propTypes = {
  inProgress: bool,

  onOpenTermsOfService: func.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const SignupForm = compose(injectIntl)(SignupFormComponent);
SignupForm.displayName = 'SignupForm';

export default SignupForm;
