import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { AvatarLarge, AvatarMedium, NamedLink } from '../../components';

import css from './ListingPage.css';

const SectionAvatar = props => {
  const { user, profileListing, params } = props;

  if(profileListing && Array.isArray(profileListing.images) && profileListing.images.length > 0){
    user.profileImage = profileListing.images[0];
  }

  return (
    <div className={css.sectionAvatar}>
      <AvatarLarge user={user} className={css.avatarDesktop} />
      <AvatarMedium user={user} className={css.avatarMobile} />
      <div className={css.avatarText}>
        <NamedLink name="ProfilePage" params={{ id: user.id.uuid }}>
          <FormattedMessage id="UserCard.showProfile" />
        </NamedLink>
      </div>
    </div>
  );
};

export default SectionAvatar;
