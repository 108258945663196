import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { Form, PrimaryButton, FieldSelect } from '../../components';

import css from './SubscriptionForm.css';
import * as validators from '../../util/validators';

class SubscriptionFormComponent extends Component {
  render() {
    return (
      <FinalForm
        {...this.props}
        render={fieldRenderProps => {
          const {
            rootClassName,
            className,
            stripeSubscriptionError,
            currentUser,
            handleSubmit,
            inProgress,
            invalid,
            intl,
          } = fieldRenderProps;

          const user = ensureCurrentUser(currentUser);

          if (!user.id) {
            return null;
          }

          // Shippo integration error
          const stripeSubscriptionErrorMessage = stripeSubscriptionError ? (
            <span className={css.error}>
              <FormattedMessage id="SubscriptionForm.genericFailure" />
            </span>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitDisabled = invalid || inProgress;

          const subscriptionPlanLabel = intl.formatMessage({ id: 'SubscriptionForm.subscriptionPlanLabel' });
          const subscriptionPlanPlaceholder = intl.formatMessage({
            id: 'SubscriptionForm.subscriptionPlanPlaceholder',
          });
          const subscriptionPlanRequired = validators.required(
            intl.formatMessage({
              id: 'SubscriptionForm.subscriptionPlanRequired',
            })
          );

          return (
            <Form
              className={classes}
              onSubmit={e => {
                handleSubmit(e);
              }}
            >
              <div className={css.contactDetailsSection}>
                <FieldSelect
                  id="priceId"
                  name="priceId"
                  label={subscriptionPlanLabel}
                  validate={subscriptionPlanRequired}
                >
                  <option disabled value="">
                    {subscriptionPlanPlaceholder}
                  </option>
                  <option value={process.env.REACT_APP_STRIPE_MONTHLY_PRICE_ID}>
                    {intl.formatMessage({ id: "SubscriptionForm.monthlyOption"})}
                  </option>
                  <option value={process.env.REACT_APP_STRIPE_ANNUAL_PRICE_ID}>
                    {intl.formatMessage({ id: "SubscriptionForm.yearlyOption"})}
                  </option>
                </FieldSelect>
              </div>

              <div className={css.bottomWrapper}>
                {stripeSubscriptionErrorMessage}
                <PrimaryButton
                  type="submit"
                  inProgress={inProgress}
                  ready={false}
                  disabled={submitDisabled}
                >
                  <FormattedMessage id="SubscriptionForm.startSubscription" />
                </PrimaryButton>
              </div>
            </Form>
          );
        }}
      />
    );
  }
}

SubscriptionFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  formId: null,
  saveEmailError: null,
  savePhoneNumberError: null,
  inProgress: false,
  sendVerificationEmailError: null,
  sendVerificationEmailInProgress: false,
  email: null,
  phoneNumber: null,
};

const { bool, func, string } = PropTypes;

SubscriptionFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  formId: string,
  saveEmailError: propTypes.error,
  savePhoneNumberError: propTypes.error,
  inProgress: bool,
  intl: intlShape.isRequired,
  onResendVerificationEmail: func.isRequired,
  ready: bool.isRequired,
  sendVerificationEmailError: propTypes.error,
  sendVerificationEmailInProgress: bool,
};

const SubscriptionForm = compose(injectIntl)(SubscriptionFormComponent);

SubscriptionForm.displayName = 'SubscriptionForm';

export default SubscriptionForm;
