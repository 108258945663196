import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
export default [
  {
    id: 'default-zurich-oberland',
    predictionPlace: {
      address: 'Zürich Oberland',
      bounds: new LatLngBounds(new LatLng(47.59975409, 8.99769147), new LatLng(47.32657783, 8.68410448)),
    },
  },
  {
    id: 'default-zurich',
    predictionPlace: {
      address: 'Kanton Zürich',
      bounds: new LatLngBounds(new LatLng(47.6947499800253, 8.98495199415534), new LatLng(47.1594420176842, 8.35777802457705)),
    },
  },
  {
    id: 'default-aargau',
    predictionPlace: {
      address: 'Kanton Aargau',
      bounds: new LatLngBounds(new LatLng(47.6207929797717, 8.4551119931053), new LatLng(47.1374770517375, 7.7135600138042)),
    },
  },
  {
    id: 'default-bern',
    predictionPlace: {
      address: 'Kanton Bern',
      bounds: new LatLngBounds(new LatLng(47.3453208985378, 8.45507699999278), new LatLng(46.326520005032, 6.86149900115696)),
    },
  },
  {
    id: 'default-freiburg',
    predictionPlace: {
      address: 'Kanton Freiburg',
      bounds: new LatLngBounds(new LatLng(47.0068009734239, 7.38026675047549), new LatLng(46.4379930178773, 6.74186844856443)),
    },
  },
  {
    id: 'default-graubunden',
    predictionPlace: {
      address: 'Kanton Graubünden',
      bounds: new LatLngBounds(new LatLng(47.0651189970794, 10.4920638273824), new LatLng(46.1694910789901, 8.6510660000018)),
    },
  },
  {
    id: 'default-jura',
    predictionPlace: {
      address: 'Kanton Jura',
      bounds: new LatLngBounds(new LatLng(47.5042939649381, 7.55747857197824), new LatLng(47.1504060005442, 6.84075103847481)),
    },
  },
  {
    id: 'default-tessin',
    predictionPlace: {
      address: 'Kanton Tessin',
      bounds: new LatLngBounds(new LatLng(46.6324849981809, 9.15953498663582), new LatLng(45.8179850070631, 8.38213712594365)),
    },
  },
];
