import React from 'react';
import { shape, string } from 'prop-types';
import { ExternalLink } from '../../components';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import css from './SectionContactsMaybe.css';

const SectionContactsMaybe = props => {
  const {
    className,
    rootClassName,
    websiteUrl,
    phoneNumber,
    listingPublicData,
    authorPublicData,
    authorMetadata,
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  let address =
    listingPublicData && listingPublicData.location && listingPublicData.location.address ?
      listingPublicData.location.address : null;
  if(address && listingPublicData.location.building) {
    address += `, ${listingPublicData.location.building}`;
  }
  const addressLine = address ? (
    <p className={css.address}>{address}</p>
  ) : null;

  const emailLine = authorPublicData && authorPublicData.email &&
    listingPublicData.type !== 'sponsor' && !authorMetadata.adminToken ? (
      <div className={css.email}>{authorPublicData.email}</div>
    ) : null;

  const phoneLine = phoneNumber ? (
    <div className={css.phoneNumber}>
      <b><FormattedMessage id="ListingPage.contactsPhoneNumber" /></b> : {phoneNumber}
    </div>
  ) : null;

  const websiteLine = websiteUrl ? (
    <div className={css.websiteUrl}>
      <ExternalLink rootClassName={css.contactLink} href={websiteUrl}>
        <FormattedMessage id="ListingPage.websiteUrlLabel" />
      </ExternalLink>
    </div>
  ) : null;

  const firmLine = listingPublicData && listingPublicData.firmName ? (
    <div className={css.firmName}>
      <b><FormattedMessage id="ListingPage.contactsFirmName" /></b> : {listingPublicData.firmName}
    </div>
  ) : null;

  return addressLine || emailLine || phoneLine ? (
    <div className={classes}>
      <h2 className={css.title}>
        <FormattedMessage id="ListingPage.contactsTitle" />
      </h2>
      {firmLine}
      {addressLine}
      {emailLine}
      {phoneLine}
      {websiteLine}
    </div>
  ) : null;
};

SectionContactsMaybe.defaultProps = { className: null, rootClassName: null };

SectionContactsMaybe.propTypes = {
  className: string,
  rootClassName: string,
  publicData: shape({
    rules: string,
  }),
};

export default SectionContactsMaybe;
