import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import {
  ManageFavoriteListingCard,
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
} from '../../components';
import { TopbarContainer } from '../../containers';

import {
  updateProfile
} from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import {
  getOwnListingsById,
  queryFavoriteListings,
} from './ManageFavoriteListingsPage.duck';
import css from './ManageFavoriteListingsPage.css';

export class ManageFavoriteListingsPageComponent extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      currentUser,
      closingListing,
      closingListingError,
      listings,
      openingListing,
      openingListingError,
      queryInProgress,
      queryListingsError,
      scrollingDisabled,
      intl,
      onUpdateProfile,
      onQueryFavoriteListings,
    } = this.props;

    const listingsAreLoaded = !queryInProgress;

    let totalItems = 0;
    if(listingsAreLoaded){
      listings.forEach( listing => {
        if(listing.attributes.publicData.type && ['table', 'product'].includes(listing.attributes.publicData.type)){
          totalItems++;
        }
      });
    }

    const loadingResults = (
      <h2>
        <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
      </h2>
    );

    const queryError = (
      <h2 className={css.error}>
        <FormattedMessage id="ManageListingsPage.queryError" />
      </h2>
    );

    const noResults =
      listingsAreLoaded && totalItems === 0 ? (
        <h1 className={css.title}>
          <FormattedMessage id="ManageListingsPage.noResults" />
        </h1>
      ) : null;

    const heading =
      listingsAreLoaded && totalItems > 0 ? (
        <h1 className={css.title}>
          <FormattedMessage
            id="ManageListingsPage.youHaveListings"
            values={{ count: totalItems }}
          />
        </h1>
      ) : (
        noResults
      );

    const closingErrorListingId = !!closingListingError && closingListingError.listingId;
    const openingErrorListingId = !!openingListingError && openingListingError.listingId;

    const title = intl.formatMessage({ id: 'ManageListingsPage.title' });

    const panelWidth = 62.5;
    // Render hints for responsive image
    const renderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${panelWidth / 2}vw`,
      `${panelWidth / 3}vw`,
    ].join(', ');


    if(listingsAreLoaded){
      listings.forEach( listing => {
        if(listing.attributes.publicData.type && ['table', 'product'].includes(listing.attributes.publicData.type)){
          totalItems++;
        }
      });
    }

    const visibleListings = listingsAreLoaded ? listings.filter( listing => {
      return listing.attributes.publicData.type &&
        ['table', 'product'].includes(listing.attributes.publicData.type);
    }) : [];

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ManageFavoriteListingsPage" />
            <UserNav selectedPageName="ManageFavoriteListingsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {queryInProgress ? loadingResults : null}
            {queryListingsError ? queryError : null}
            <div className={css.listingPanel}>
              {heading}
              <div className={css.listingCards}>
                {visibleListings.map(l => {
                  return l.attributes.publicData.type !== 'profile' ? (
                    <ManageFavoriteListingCard
                      className={css.listingCard}
                      key={l.id.uuid}
                      currentUser={currentUser}
                      listing={l}
                      actionsInProgressListingId={openingListing || closingListing}
                      hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                      hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                      renderSizes={renderSizes}
                      onUpdateProfile={onUpdateProfile}
                      onQueryFavoriteListings={onQueryFavoriteListings}
                    />
                  ) : null;
                })}
              </div>
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ManageFavoriteListingsPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { arrayOf, bool, shape, string } = PropTypes;

ManageFavoriteListingsPageComponent.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(propTypes.listing),
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ManageFavoriteListingsPage;

  const listings = getOwnListingsById(state, currentPageResultIds);

  return {
    currentUser,
    currentPageResultIds,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  };
};

const mapDispatchToProps = dispatch => ({
  onQueryFavoriteListings: () => dispatch(queryFavoriteListings()),
  onUpdateProfile: actionPayload => dispatch(updateProfile(actionPayload)),
});

const ManageFavoriteListingsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ManageFavoriteListingsPageComponent);

ManageFavoriteListingsPage.loadData = () => {
  return queryFavoriteListings();
};

export default ManageFavoriteListingsPage;
