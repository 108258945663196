/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'category',
    label: 'Kategorie',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // Gewerbe & Geschäfte
        { key: 'gewerbe_n_geschafte', label: 'Gewerbe & Geschäfte', parent: null, type: 'product' },
        { key: 'detailhandel', label: 'Detailhandel', parent: 'gewerbe_n_geschafte', type: 'product', hide_price: true },
        { key: 'laden_n_geschafte_gewerbe', label: 'Gewerbe', parent: 'gewerbe_n_geschafte', type: 'product', hide_price: true },
        { key: 'laden_n_geschafte_dienstleistungen', label: 'Dienstleistungen', parent: 'gewerbe_n_geschafte', type: 'product', hide_price: true },
        { key: 'laden_n_geschafte_freelancer', label: 'Freelancer', parent: 'gewerbe_n_geschafte', type: 'product' },
        { key: 'freelancer_nachhilfe', label: 'Nachhilfe', parent: 'laden_n_geschafte_freelancer', type: 'product' },
        { key: 'freelancer_allgemeinheit', label: 'für die Allgemeinheit', parent: 'laden_n_geschafte_freelancer', type: 'product' },
        { key: 'freelancer_einkaufshilfe', label: 'Einkaufshilfe', parent: 'laden_n_geschafte_freelancer', type: 'product' },
        { key: 'freelancer_fahrdienste', label: 'Fahrdienste', parent: 'laden_n_geschafte_freelancer', type: 'product' },
        { key: 'freelancer_diverses', label: 'Diverses', parent: 'laden_n_geschafte_freelancer', type: 'product' },
        // Deals & Promotion
        { key: 'deals_n_promotion', label: 'Deals & Promotion', parent: null, type: 'product' },
        { key: 'essen', label: 'Essen', parent: 'deals_n_promotion', type: 'product' },
        { key: 'trinken', label: 'Trinken', parent: 'deals_n_promotion', type: 'product' },
        { key: 'einkaufen', label: 'Einkaufen', parent: 'deals_n_promotion', type: 'product' },
        { key: 'deals_n_promotion_gesund_n_schon', label: 'Gesund & Schön', parent: 'deals_n_promotion', type: 'product' },
        { key: 'deals_n_promotion_gewerbe', label: 'Gewerbe', parent: 'deals_n_promotion', type: 'product' },
        { key: 'deals_n_promotion_dienstleistungen', label: 'Dienstleistungen', parent: 'deals_n_promotion', type: 'product' },
        // Essen & Geniessen
        { key: 'essen_n_geniessen', label: 'Essen & Geniessen', parent: null, type: 'product' },
        { key: 'bar', label: 'Bar', parent: 'essen_n_geniessen', type: 'product' },
        { key: 'drinks', label: 'Drinks', parent: 'bar', type: 'product' },
        { key: 'bar_plattli', label: 'Plättli', parent: 'bar', type: 'product' },
        { key: 'bar_spezialitaten', label: 'Spezialitäten', parent: 'bar', type: 'product' },
        { key: 'bar_tagesangebot', label: 'Tagesangebot', parent: 'bar', type: 'product' },
        { key: 'restaurants', label: 'Restaurants', parent: 'essen_n_geniessen', type: 'product' },
        { key: 'mit_terrrasse', label: 'mit Terrrasse', parent: 'restaurants', type: 'product' },
        { key: 'mit_garten', label: 'mit Garten', parent: 'restaurants', type: 'product' },
        { key: 'restaurants_plattli', label: 'Plättli', parent: 'restaurants', type: 'product' },
        { key: 'restaurants_spezialitaten', label: 'Spezialitäten', parent: 'restaurants', type: 'product' },
        { key: 'restaurants_tagesangebot', label: 'Tagesangebot', parent: 'restaurants', type: 'product' },
        { key: 'take_away', label: 'Take Away', parent: 'essen_n_geniessen', type: 'product' },
        { key: 'take_away_tagesangebot', label: 'Tagesangebot', parent: 'take_away', type: 'product' },
        { key: 'take_away_spezialitaten', label: 'Spezialitäten', parent: 'take_away', type: 'product' },
        { key: 'essen_n_geniessen_hotel', label: 'Hotel', parent: 'essen_n_geniessen', type: 'product' },
        // Blumen & Kräuter
        { key: 'blumen_n_krauter', label: 'Blumen & Kräuter', parent: null, type: 'product' },
        { key: 'blumen_n_strausse', label: 'Blumen & Sträusse', parent: 'blumen_n_krauter', type: 'product' },
        { key: 'tagesstrausse', label: 'Tagessträusse', parent: 'blumen_n_krauter', type: 'product' },
        { key: 'themen_n_anlasse', label: 'Themen & Anlässe', parent: 'blumen_n_krauter', type: 'product' },
        { key: 'krauter', label: 'Kräuter', parent: 'blumen_n_krauter', type: 'product' },
        // Brot & Backwaren
        { key: 'brot_n_backwaren', label: 'Brot & Backwaren', parent: null, type: 'product' },
        { key: 'aus_dem_ofen', label: 'aus dem Ofen', parent: 'brot_n_backwaren', type: 'product' },
        { key: 'susses', label: 'Süsses', parent: 'brot_n_backwaren', type: 'product' },
        { key: 'spezialitaten', label: 'Spezialitäten', parent: 'brot_n_backwaren', type: 'product' },
        // Lebensmittel & Konsumartikel
        { key: 'lebensmittel_n_konsumartikel', label: 'Lebensmittel & Konsumartikel', parent: null, type: 'product' },
        { key: 'metzgerei', label: 'Metzgerei', parent: 'lebensmittel_n_konsumartikel', type: 'product' },
        { key: 'grill', label: 'Grill', parent: 'metzgerei', type: 'product' },
        { key: 'metzgerei_spezialitaten', label: 'Spezialitäten', parent: 'metzgerei', type: 'product' },
//        { key: 'sommer', label: 'Sommer', parent: 'metzgerei', type: 'product' },
//        { key: 'winter', label: 'Winter', parent: 'metzgerei', type: 'product' },
        { key: 'wurst', label: 'Wurst', parent: 'metzgerei', type: 'product' },
        { key: 'fischerei', label: 'Fischerei', parent: 'lebensmittel_n_konsumartikel', type: 'product' },
        { key: 'kaserei', label: 'Käserei', parent: 'lebensmittel_n_konsumartikel', type: 'product' },
        { key: 'milchprodukte', label: 'Milchprodukte', parent: 'lebensmittel_n_konsumartikel', type: 'product' },
        { key: 'obst_gemuse_fruchte', label: 'Obst Gemüse Früchte', parent: 'lebensmittel_n_konsumartikel', type: 'product' },
        { key: 'lebensmittel', label: 'Lebensmittel', parent: 'lebensmittel_n_konsumartikel', type: 'product' },
        { key: 'konsumartikel', label: 'Konsumartikel', parent: 'lebensmittel_n_konsumartikel', type: 'product' },
        { key: 'vom_hof', label: 'vom Hof', parent: 'lebensmittel_n_konsumartikel', type: 'product' },
        { key: 'fleisch', label: 'Fleisch', parent: 'vom_hof', type: 'product' },
        { key: 'milch', label: 'Milch', parent: 'vom_hof', type: 'product' },
        { key: 'kase', label: 'Käse', parent: 'vom_hof', type: 'product' },
        { key: 'gemuse', label: 'Gemüse', parent: 'vom_hof', type: 'product' },
        { key: 'fruchte_n_obst', label: 'Früchte & Obst', parent: 'vom_hof', type: 'product' },
        { key: 'krauter', label: 'Kräuter', parent: 'vom_hof', type: 'product' },
        { key: 'safte', label: 'Säfte', parent: 'vom_hof', type: 'product' },
        { key: 'geschenke', label: 'Geschenke', parent: 'vom_hof', type: 'product' },
        { key: 'holz', label: 'Holz', parent: 'vom_hof', type: 'product' },
        // Mode & Schmuck
        { key: 'mode_n_schmuck', label: 'Mode & Schmuck', parent: null, type: 'product', sizes_enabled: true },
        { key: 'mode_n_bekleidung', label: 'Mode & Bekleidung', parent: 'mode_n_schmuck', type: 'product', sizes_enabled: true },
        { key: 'mode_n_bekleidung_frauen', label: 'Frauen', parent: 'mode_n_bekleidung', type: 'product', sizes_enabled: true },
        { key: 'mode_n_bekleidung_manner', label: 'Männer', parent: 'mode_n_bekleidung', type: 'product', sizes_enabled: true },
        { key: 'mode_n_bekleidung_kinder', label: 'Kinder', parent: 'mode_n_bekleidung', type: 'product', sizes_enabled: true },
        { key: 'schmuck', label: 'Schmuck', parent: 'mode_n_schmuck', type: 'product', sizes_enabled: true },
        { key: 'schmuck_frauen', label: 'Frauen', parent: 'schmuck', type: 'product', sizes_enabled: true },
        { key: 'schmuck_manner', label: 'Männer', parent: 'schmuck', type: 'product', sizes_enabled: true },
        { key: 'schmuck_kinder', label: 'Kinder', parent: 'schmuck', type: 'product', sizes_enabled: true },
        { key: 'uhren', label: 'Uhren', parent: 'mode_n_schmuck', type: 'product', sizes_enabled: true },
        { key: 'schuhe', label: 'Schuhe', parent: 'mode_n_schmuck', type: 'product', sizes_enabled: true },
        { key: 'schuhe_frauen', label: 'Frauen', parent: 'schuhe', type: 'product', sizes_enabled: true },
        { key: 'schuhe_manner', label: 'Männer', parent: 'schuhe', type: 'product', sizes_enabled: true },
        { key: 'schuhe_kinder', label: 'Kinder', parent: 'schuhe', type: 'product', sizes_enabled: true },
        // Wohnen & Handwerk
        { key: 'wohnen_n_handwerk', label: 'Wohnen & Handwerk', parent: null, type: 'product' },
        { key: 'wohnen_n_einrichten', label: 'Wohnen & Einrichten', parent: 'wohnen_n_handwerk', type: 'product' },
        { key: 'bauen_n_renovieren', label: 'Bauen & Renovieren', parent: 'wohnen_n_handwerk', type: 'product' },
        { key: 'dekoration_n_accessoires', label: 'Dekoration & Accessoires', parent: 'wohnen_n_handwerk', type: 'product' },
        { key: 'gerate_n_elektronik', label: 'Geräte & Elektronik', parent: 'wohnen_n_handwerk', type: 'product' },
        { key: 'finanzen_n_recht', label: 'Finanzen & Recht', parent: 'wohnen_n_handwerk', type: 'product' },
        { key: 'garten_n_pflanzen', label: 'Garten & Pflanzen', parent: 'wohnen_n_handwerk', type: 'product' },
        // Getränkte & Wein
        { key: 'getrankte_n_wein', label: 'Getränkte & Wein', parent: null, type: 'product' },
        { key: 'rotwein', label: 'Rotwein', parent: 'getrankte_n_wein', type: 'product' },
        { key: 'weisswein', label: 'Weisswein', parent: 'getrankte_n_wein', type: 'product' },
        { key: 'bier', label: 'Bier', parent: 'getrankte_n_wein', type: 'product' },
        { key: 'sussgetranke', label: 'Süssgetränke', parent: 'getrankte_n_wein', type: 'product' },
        { key: 'diverses', label: 'Diverses', parent: 'getrankte_n_wein', type: 'product' },
        { key: 'schnaps', label: 'Schnaps', parent: 'getrankte_n_wein', type: 'product' },
        { key: 'spirituosen', label: 'Spirituosen', parent: 'getrankte_n_wein', type: 'product' },
        // Gesund & Schön
        { key: 'gesund_n_schon', label: 'Gesund & Schön', parent: null, type: 'product' },
        { key: 'medizin_n_gesundheit', label: 'Medizin & Gesundheit', parent: 'gesund_n_schon', type: 'product' },
        { key: 'beauty_n_wellness', label: 'Beauty & Wellness', parent: 'gesund_n_schon', type: 'product' },
        { key: 'augen', label: 'Augen', parent: 'gesund_n_schon', type: 'product' },
        { key: 'hande', label: 'Hände', parent: 'gesund_n_schon', type: 'product' },
        { key: 'fusse', label: 'Füsse', parent: 'gesund_n_schon', type: 'product' },
        { key: 'haare', label: 'Haare', parent: 'gesund_n_schon', type: 'product' },
        // Sport
        { key: 'sport', label: 'Sport', parent: null, type: 'product', sizes_enabled: true },
        { key: 'damen', label: 'Damen', parent: 'sport', type: 'product', sizes_enabled: true },
        { key: 'herren', label: 'Herren', parent: 'sport', type: 'product', sizes_enabled: true },
        { key: 'kinder', label: 'Kinder', parent: 'sport', type: 'product', sizes_enabled: true },
        { key: 'fachgeschaft', label: 'Fachgeschäft', parent: 'sport', type: 'product', sizes_enabled: true },
        // Geschenke
        { key: 'geschenke', label: 'Geschenke', parent: null, type: 'product' },
        { key: 'besondere_anlasse', label: 'Besondere Anlässe', parent: 'geschenke', type: 'product' },
        { key: 'geschenke_frau', label: 'Frau', parent: 'geschenke', type: 'product' },
        { key: 'geschenke_mann', label: 'Mann', parent: 'geschenke', type: 'product' },
        { key: 'geschenke_kinder', label: 'Kinder', parent: 'geschenke', type: 'product' },
        { key: 'gutscheine', label: 'Gutscheine', parent: 'geschenke', type: 'product' },
        // Freizeit & Tourismus
        { key: 'freizeit_n_tourismus', label: 'Freizeit & Tourismus', parent: null, type: 'product' },
        { key: 'hotels_n_unterkünfte', label: 'Hotels & Unterkünfte', parent: 'freizeit_n_tourismus', type: 'product' },
        { key: 'reisen_n_tourismus', label: 'Reisen & Tourismus', parent: 'freizeit_n_tourismus', type: 'product' },
        { key: 'ausgang_n_unterhaltung', label: 'Ausgang & Unterhaltung', parent: 'freizeit_n_tourismus', type: 'product' },
        { key: 'freizeit_n_sport', label: 'Freizeit & Sport', parent: 'freizeit_n_tourismus', type: 'product' },
        { key: 'kunst_n_kultur', label: 'Kunst & Kultur', parent: 'freizeit_n_tourismus', type: 'product' },
        // Second Hand
        { key: 'second_hand', label: 'Second Hand', parent: null, type: 'product', sizes_enabled: true },
        { key: 'kleider', label: 'Kleider', parent: 'second_hand', type: 'product', sizes_enabled: true },
        { key: 'kleider_frauen', label: 'Frauen', parent: 'kleider', type: 'product', sizes_enabled: true },
        { key: 'kleider_herren', label: 'Herren', parent: 'kleider', type: 'product', sizes_enabled: true },
        { key: 'kleider_kinder', label: 'Kinder', parent: 'kleider', type: 'product', sizes_enabled: true },
        { key: 'second_hand_schuhe', label: 'Schuhe', parent: 'second_hand', type: 'product', sizes_enabled: true },
        { key: 'frauenschuhe', label: 'Frauenschuhe', parent: 'second_hand_schuhe', type: 'product', sizes_enabled: true },
        { key: 'herrenschuhe', label: 'Herrenschuhe', parent: 'second_hand_schuhe', type: 'product', sizes_enabled: true },
        { key: 'kinderschuhe', label: 'Kinderschuhe', parent: 'second_hand_schuhe', type: 'product', sizes_enabled: true },
        { key: 'spielwaren', label: 'Spielwaren', parent: 'second_hand', type: 'product', sizes_enabled: true },
        { key: 'mobel', label: 'Möbel', parent: 'second_hand', type: 'product', sizes_enabled: true },
        { key: 'haushalt', label: 'Haushalt', parent: 'second_hand', type: 'product', sizes_enabled: true },
        { key: 'bucher_n_zeitschriften', label: 'Bücher & Zeitschriften', parent: 'second_hand', type: 'product', sizes_enabled: true },
        { key: 'outdoor', label: 'Outdoor', parent: 'second_hand', type: 'product', sizes_enabled: true },
        { key: 'zu_verschenken_n_gratis', label: 'zu verschenken & gratis', parent: 'second_hand', type: 'product', sizes_enabled: true },
      ],
    },
  },
  {
    id: 'amenities',
    label: 'Optionen',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_amenities'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'januar', label: 'Januar', order: 0, },
        { key: 'februar', label: 'Februar', order: 1, },
        { key: 'marz', label: 'März', order: 2, },
        { key: 'april', label: 'April', order: 3, },
        { key: 'ganzjährig', label: 'ganzjährig', order: 12 },
        { key: 'lieferung', label: 'Lieferung', order: 13 },
        { key: 'mai', label: 'Mai', order: 4 },
        { key: 'juni', label: 'Juni', order: 5 },
        { key: 'juli', label: 'Juli', order: 6 },
        { key: 'august', label: 'August', order: 7 },
        { key: 'geschenkideen', label: 'Geschenkideen', order: 14 },
        { key: 'services', label: 'Services', order: 15 },
        { key: 'september', label: 'September', order: 8 },
        { key: 'oktober', label: 'Oktober', order: 9 },
        { key: 'november', label: 'November', order: 10 },
        { key: 'dezember', label: 'Dezember', order: 11 },
        { key: 'versand', label: 'Versand', order: 16 },
      ],
    },
  },
  {
    id: 'shoe_sizes',
    label: 'SchuhGr',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_shoeSizes'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: '16_17', label: '16/17', order: 0 },
        { key: '17_18', label: '17/18', order: 1 },
        { key: '18_19', label: '18/19', order: 2 },
        { key: '20_21', label: '20/21', order: 3 },
        { key: '21_22', label: '21/22', order: 4 },
        { key: '22_23', label: '22/23', order: 5 },
        { key: '23_24', label: '23/24', order: 6 },
        { key: '24_25', label: '24/25', order: 7 },
        { key: '25_26', label: '25/26', order: 8 },
        { key: '27_28', label: '27/28', order: 9 },
        { key: '28_29', label: '28/29', order: 10 },
        { key: '29_30', label: '29/30', order: 11 },
        { key: '30_31', label: '30/31', order: 12 },
        { key: '31_32', label: '31/32', order: 13 },
        { key: '32_33', label: '32/33', order: 14 },
        { key: '34', label: '34', order: 15 },
        { key: '35_35_5', label: '35/35.5', order: 16 },
        { key: '36_36_5', label: '36/36.5', order: 17 },
        { key: '37_37_5', label: '37/37.5', order: 18 },
        { key: '38_38_5', label: '38/38.5', order: 19 },
        { key: '39_39_5', label: '39/39.5', order: 20 },
        { key: '40_40_5', label: '40/40.5', order: 21 },
        { key: '41_41_5', label: '41/41.5', order: 22 },
        { key: '42_42_5', label: '42/42.5', order: 23 },
        { key: '43_43_5', label: '43/43.5', order: 24 },
        { key: '44_44_5', label: '44/44.5', order: 25 },
        { key: '45_45_5', label: '45/45.5', order: 26 },
        { key: '46_46_5', label: '46/46.5', order: 27 },
        { key: '47_47_5', label: '47/47.5', order: 28 },
        { key: '48_48_5', label: '48/48.5', order: 29 },
        { key: '49_49_5', label: '49/49.5', order: 30 },
        { key: '50', label: '50', order: 31 },
        { key: 'kinder', label: 'Kinder', order: 32 },
        { key: 'frauen', label: 'Frauen', order: 33 },
        { key: 'herren', label: 'Herren', order: 34 },
      ],
    },
  },
  {
    id: 'cloth_sizes',
    label: 'KleiderGr',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_clothSizes'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'xs', label: 'XS', order: 0 },
        { key: 's', label: 'S', order: 1 },
        { key: 'm', label: 'M', order: 2 },
        { key: 'l', label: 'L', order: 3 },
        { key: 'xl', label: 'XL', order: 4 },
        { key: 'xxl', label: 'XXL', order: 5 },
        { key: 'kinder', label: 'Kinder', order: 6 },
        { key: 'frauen', label: 'Frauen', order: 7 },
        { key: 'herren', label: 'Herren', order: 8 },
      ],
    },
  },
  {
    id: 'keyword',
    label: 'Stichwort',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  {
    id: 'price',
    label: 'Preis',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'dates',
    label: 'Datum',
    type: 'BookingDateRangeFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates'],
    config: {},
  },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Neueste' },
    { key: '-createdAt', label: 'Älteste' },
    { key: '-price', label: 'Geringster Preis' },
    { key: 'price', label: 'Höchster Preis' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};

export const productUnits = [
  { key: 'pro_person', label: 'pro Person' },
  { key: 'pro_stunde', label: 'pro Stunde' },
  { key: 'pro_tisch', label: 'pro Tisch' },
  { key: 'becher', label: 'Becher' },
  { key: 'einheit', label: 'Einheit' },
  { key: 'flasche_0_5', label: 'Flasche 0.5' },
  { key: 'flasche_0_75', label: 'Flasche 0.75' },
  { key: 'flasche_1_lt', label: 'Flasche 1 Lt.' },
  { key: 'gramm', label: 'Gramm' },
  { key: '100_gramm', label: '100 Gramm' },
  { key: '250_gramm', label: '250 Gramm' },
  { key: '500_gramm', label: '500 Gramm' },
  { key: '1_kilogramm', label: '1 Kilogramm' },
  { key: 'kilogramm', label: 'Kilogramm' },
  { key: 'korb', label: 'Korb' },
  { key: 'liter', label: 'Liter' },
  { key: 'packung', label: 'Packung' },
  { key: 'paar', label: 'Paar' },
  { key: 'preis_auf_anfrage', label: 'Preis auf Anfrage' },
  { key: 'schachtel', label: 'Schachtel' },
  { key: 'stuck', label: 'Stück' },
  { key: 'preis_pro_angebot', label: 'Preis pro Angebot' },
  { key: 'preis_pro_einheit', label: 'Preis pro Einheit' },
];
