import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './TermsOfService.css';

const TermsOfService = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <p className={css.lastUpdated}>Letztes Update: 9. Juni 2021</p>

      <p>
        Dorfplatz24 ist die CH-Sharing Plattform für Produkte, Angebote und Dienstleistungen
        des lokalen Gewerbes. Wir leisten einen Beitrag zur besseren Vermarktung
        des lokalen Gewerbes und zur Attraktivität des Dorfes.
        <br/><br/>
        Bitte lesen Sie unsere untenstehende Dienstleistungsvereinbarung sorgfältig
        durch, bevor Sie unseren Online-Marktplatz und dessen Dienstleistungen
        nutzen, da diese Ihre Nutzung unserer Plattform regeln.
        <br/><br/>
        Wichtig ist, dass diese Nutzungsvereinbarung einen Verzicht auf Sammelklagen
        und Gerichtsverfahren sowie eine Vereinbarung zur Einreichung aller Ansprüche
        und Streitigkeiten bei einem verbindlichen Schiedsverfahren in Abschnitt
        4 enthält. Wenn Sie nicht allen Bedingungen dieser Nutzungsvereinbarung
        zustimmen, einschliesslich jener, die Streitigkeiten in Abschnitt 4 regeln,
        dürfen Sie unsere Plattform oder Dienstleistungen nicht nutzen.
      </p>

      <h2>Dienstleistungsvereinbarung</h2>
      <p>
        Diese Dorfplatz24-Dienstleistungsvereinbarung („Vereinbarung“) ist ein Vertrag
        zwischen der Einzelfirma Dorfplatz24 („Dorfplatz24“ oder „wir“) und
        der/den Person(en), die bei Dorfplatz24 registriert ist/sind („Benutzer“ oder „Sie“).
        Diese Vereinbarung beschreibt die Geschäftsbedingungen, die für Ihre Nutzung
        des Dorfplatz24-Marktplatzes zum Auflisten von Produkten, Angeboten und
        Dienstleistungen über unsere Website oder Apps gelten, und legt die Verpflichtungen
        fest, die zwischen Ihnen und Dorfplatz24 sowie zwischen Ihnen und anderen
        Benutzern entstehen.
        <br/><br/>
        In dieser Vereinbarung bedeutet der Begriff „Dienstleistung“ die Dienstleistung,
        mit der Sie sich über Produkte und Angebote oder Ihre Produkte, Angebote
        und Ihre Dienstleistungen über unsere Website oder Apps (die „Plattform“)
        anderen anbieten können. Der Begriff Dienstleistung umfasst keine Dienstleistungen,
        die von Dritten bereitgestellt werden. Die Begriffe „Produkt“ und „Angebot“
        beziehen sich jeweils auf die Auflistung eines Produktes oder Angebotes
        auf unserem Marktplatz.
        <br/><br/>
        Ein Anbieter kann ein Produkt oder ein Angebot „auflisten“, indem es bestimmte
        Details dazu bereitstellt, einschliesslich Verfügbarkeit, Preisgestaltung
        und Nutzungsbedingungen oder -beschränkungen; dieser Beitrag über ein Produkt
        oder Angebot wird als „Listing“ bezeichnet.
        <br/><br/>
        Denken Sie daran, Dorfplatz24 ist keine Partei dieser Vereinbarung oder
        jeglicher anderen, wir sind lediglich eine Plattform, die Konsumenten hilft,
        Produkte und Angebote zu entdecken, und den Anbietern hilft, mit
        den Konsumenten/Kunden zu kommunizieren.
        <br/><br/>
        Der tatsächliche Abschluss eines Vertrags und etwaige Zahlungen zwischen
        Parteien fallen nicht in das Leistungsspektrum von Dorfplatz24. Dorfplatz24
        erhebt oder verarbeitet keine Gebühren für Verträge zwischen Mitgliedern.
        <br/><br/>
        Diese Vereinbarung ist in 5 Abschnitte unterteilt:
        <br/>
        <ul style={{listStyleType: 'disc'}}>
          <li style={{fontSize: '16px'}}>
            Abschnitt 1: Allgemeine Geschäftsbedingungen enthält allgemeine Geschäftsbedingungen,
            die Ihr Konto beschreiben, einschliesslich der Erstellung eines Kontos,
            der Annahme oder Durchführung von Buchungsanfragen und der Beschreibung,
            wie wir mit Ihnen kommunizieren.
          </li>
          <br/>
          <li style={{fontSize: '16px'}}>
            Abschnitt 2: Plattform und Dienstleistung beschreibt, wie Sie die Plattform
            und die Dienstleistung nutzen dürfen und wie nicht, und beschreibt auch,
            wie wir Daten verwenden.
          </li>
          <br/>
          <li style={{fontSize: '16px'}}>
            Abschnitt 3: Dorfplatz24-Dienstleistungen legt die Verpflichtungen von
            Anbietern fest, einschliesslich dem Listing eines Produktes und Angebotes
            und der Erwartungen an ein über die Dienstleistung gelistetes Produkt/Angebot,
            und die Verpflichtungen der Anbieter
          </li>
          <br/>
          <li style={{fontSize: '16px'}}>
            Abschnitt 4: Kündigung, Streitigkeiten und verbindliche Schiedsverfahren
            beschreibt die Kündigung oder Aussetzung dieser Vereinbarung oder Ihres
            Kontos sowie den Prozess der Beilegung von Streitigkeiten. Dieser
            Abschnitt enthält den Verzicht auf Sammelklagen und Gerichtsverfahren
            sowie eine Vereinbarung, alle Ansprüche und Streitigkeiten einem verbindlichen
            Schiedsverfahren zu unterziehen. Bitte lesen Sie diesen Abschnitt sorgfältig
            durch, bevor Sie diese Vereinbarung akzeptieren. Sie dürfen die Plattform
            oder die Dienstleistungen nicht nutzen, wenn Sie diesem Abschnitt nicht zustimmen.
          </li>
          <br/>
          <li style={{fontSize: '16px'}}>
            Abschnitt 5: Zusätzliche rechtliche Bestimmungen enthält zusätzliche
            rechtliche Bestimmungen, einschliesslich unserer Fähigkeit, diese
            Vereinbarung, Haftungsausschlüsse und Haftungsbeschränkungen sowie
            einige spezifische Bestimmungen für Benutzer ausserhalb der Schweiz
            zu aktualisieren. Es enthält auch andere Dokumente, die Ihre Nutzung
            der Dienstleistungen regeln, einschliesslich unserer Community-Richtlinien,
            Gebühren, Datenschutzrichtlinien sowie Stornierungs- und Rückerstattungsrichtlinien.
          </li>
          <br/>
          Wenn Sie Fragen zu dieser Vereinbarung haben, wenden Sie sich bitte an
          support@Dorfplatz24.com Durch Ihre Nutzung der Plattform oder der Dienstleistungen
          akzeptieren Sie diese Vereinbarung in der jeweils gültigen Fassung weiterhin.
        </ul>
      </p>

      <h2>Abschnitt 1: Allgemeine Geschäftsbedingungen</h2>
      <p>
        1.1 Dienstleistungen
        <br/><br/>
        Dorfplatz24 bietet Ihnen Zugriff auf Dienstleistungen über die Plattform.
        Sie können über unsere Website oder Apps auf die Plattform zugreifen.
        Sie können die Dienstleistung verwenden, um Produkte und Angebote aufzulisten,
        als Konsument Produkte und Angebote zu finden, mit anderen Benutzern zu
        kommunizieren und auf Ihr Konto zuzugreifen.
        <br/><br/>
        Die Dorfplatz24-Plattform fungiert ausschliesslich als Kommunikationsplattform
        und unterstützt weder vertragliche Vereinbarungen, Inkasso von Zahlungen
        noch Garantien. Dorfplatz24 ist keine Partei von, hat keine Beteiligung
        oder kein Interesse an, gibt keine Zusicherungen oder Gewährleistungen in
        Bezug auf und übernimmt keine Verantwortung oder Haftung in Bezug auf
        Kommunikationen, Transaktionen, Interaktionen, Streitigkeiten oder irgendwelche
        Beziehungen zwischen Ihnen und einem anderen Mitglied, Person oder Organisation.
        Sie sind für Ihre Interaktionen mit anderen Mitgliedern unserer Dienstleistungen
        allein verantwortlich. Wir behalten uns das Recht vor, sind jedoch nicht
        verpflichtet, die Interaktionen zwischen Ihnen und anderen Mitgliedern
        unserer Dienstleistungen zu überwachen.
        <br/><br/>
        Wir verbessern die Plattform oder die Dienstleistung kontinuierlich für
        alle Benutzer und behalten uns das Recht vor, jederzeit Änderungen vorzunehmen.
        Änderungen, die wir an der Plattform oder der Dienstleistung vornehmen,
        einschliesslich der Einstellung bestimmter Funktionen, wirken sich auf alle
        Benutzer aus und wir versuchen, Störungen für Benutzer so gering wie möglich
        zu halten. Diese Änderungen können sich jedoch unterschiedlich auf Benutzer
        auswirken. Wir sind nicht verantwortlich, wenn bestimmte Änderungen, die wir
        an der Plattform oder der Dienstleistung vornehmen, sich nachteilig auf
        Ihre Nutzung auswirken.
        <br/><br/>
        1.2 Ihr Konto
        <br/><br/>
        Bevor Sie die Funktionen unserer Plattform oder unserer Dienstleistungen
        nutzen können, müssen Sie ein Konto bei Dorfplatz24 („Konto“) erstellen
        und uns Informationen über sich selbst oder Ihr Unternehmen zur Verfügung
        stellen. Wir können Sie jederzeit bitten, Ihr Konto zu aktualisieren oder
        zusätzliche oder andere Informationen bereitzustellen. Dies beinhaltet
        die Anforderung zusätzlicher Details zu Pferden. Alle Mitarbeiter, Auftragnehmer
        oder Dritten, die ein neues Konto eröffnen oder ein bestehendes Konto verwenden
        (zusammen „Vertreter“), versichern und garantieren, dass sie befugt sind,
        im Namen einer Person oder Organisation zu handeln, die sie behaupten
        zu vertreten; und wenn eine solche Autorisierung nicht von der Person
        oder Organisation bereitgestellt wird, erkennen diese Vertreter die persönliche
        Haftung für alle Verpflichtungen und Verbindlichkeiten an, die sich aus
        oder im Zusammenhang mit der Nutzung des Kontos durch diese Vertreter ergeben.
        <br/><br/>
        Sie müssen eine E-Mail-Adresse und ein Passwort angeben, um Ihr Konto sicher
        zu halten („Anmeldeinformationen“), und sich damit einverstanden erklären,
        Ihre Anmeldeinformationen privat und sicher zu halten. Sie sind für alle
        Aktionen verantwortlich, die in Ihrem Konto unter Verwendung Ihrer Anmeldeinformationen
        ausgeführt werden, unabhängig davon, ob diese von Vertretern autorisiert
        oder ausgeführt wurden, um ein Pferd oder Dienstleistungen aufzulisten oder
        zu buchen. Sie sind allein verantwortlich für die Handlungen oder Mitteilungen
        Ihrer Vertreter. Wir sind nicht verantwortlich und lehnen jede Haftung für
        die Nutzung Ihres Kontos durch Ihre Vertreter ab.
        <br/><br/>
        Beim Erstellen oder Verwenden Ihres Kontos müssen Sie möglicherweise Informationen
        über sich selbst oder Ihr Unternehmen angeben. Sie stellen uns nur Inhalte
        zur Verfügung, die Sie besitzen oder zu deren Bereitstellung Sie berechtigt
        sind, und stellen sicher, dass die Inhalte korrekt und vollständig sind.
        Sie müssen alle Inhalte auf dem neuesten Stand halten, einschliesslich Ihrer
        Kontakt- oder Zahlungsinformationen. Wir behalten uns das Recht vor, sind
        jedoch nicht verpflichtet, öffentliche und private Datenquellen zu verwenden,
        um die Richtigkeit von Inhalten zu überprüfen. Dies kann die Überprüfung
        Ihrer Identität oder Geschäftsinformationen oder die Überprüfung von Informationen
        zu ihrem Hof umfassen. Sie werden uns zusätzliche Informationen zur Verfügung
        stellen, um die Richtigkeit oder Vollständigkeit der von Ihnen bereitgestellten
        Inhalte zu überprüfen und wir können Ihre Nutzung der Plattform oder der Dienstleistung
        von unserer Fähigkeit abhängig machen, die Richtigkeit und Vollständigkeit
        dieser Inhalte zu überprüfen. Wenn Sie uns die erforderlichen Inhalte nicht
        zur Verfügung stellen, wenn wir diese anfragen, können wir Ihr Konto sperren
        oder kündigen.
        <br/><br/>
        Wenn Sie nicht mindestens 18 Jahre alt sind, dürfen Sie kein Konto eröffnen,
        nicht auf die Plattform zugreifen oder die Dienstleistungen nutzen. Benutzer
        (einschlieslich Vertreter), die die Dienstleistungen oder die Plattform nutzen,
        um Produkte, Angebote, Dienstleistungen im Namen von Personen unter 18 Jahren
        aufzulisten oder zu buchen oder den Zugang oder die Nutzung von Angeboten
        durch Personen unter 18 Jahren zu ermöglichen, übernehmen die persönliche
        Haftung für alle Handlungen oder Versäumnisse solcher Personen.
        <br/><br/>
        Sie können Ihr Konto jederzeit schliessen, indem Sie uns eine E-Mail an
        support@Dorfplatz24.com senden. Sie sind für alle Aktivitäten verantwortlich,
        die mit Ihrem Konto verbunden sind, bevor es geschlossen wird, einschliesslich
        aller Verbindlichkeiten, die durch die Nutzung der Plattform oder der Dienstleistung
        verursacht werden oder daraus resultieren. Sie verstehen, dass wir möglicherweise
        Inhalte behalten und weiterhin öffentliche Inhalte (einschliesslich Bewertungen
        von Produzenten) anzeigen und verwenden, die uns vor der Schliessung Ihres Kontos
        zur Verfügung gestellt wurden. Nach eigenen Ermessen können wir Ihr Konto
        sperren oder kündigen.
        <br/><br/>
        1.3 Einhaltung von Gesetzen
        <br/><br/>
        In dieser Vereinbarung bedeutet „Gesetze“ alle geltenden Bundes-, Landes-,
        lokalen und anderen staatlichen Gesetze, Vorschriften, Verordnungen, Kodexe,
        Regeln, Gerichtsbeschlüsse sowie alle aufgezeichneten und nicht aufgezeichneten
        privaten Verträge, Beschränkungen, Zusicherungen und sonstigen Vereinbarungen.
        Sie werden alle Gesetze einhalten, die für Ihre Nutzung des Angebotes,
        der Dienstleistungen, der Plattform und der Dorfplatz24-Services gelten,
        sei es als Produzent oder als Konsument/Kunde. Wir stellen möglicherweise
        Informationen zur Verfügung, die Ihnen helfen, bestimmte Verpflichtungen
        zur Nutzung oder Listing von Produkten/Angeboten zu verstehen, wir sind
        jedoch nicht berechtigt, Rechtsberatung zu leisten, und bieten diese auch
        nicht an. Sie sind allein für die Einhaltung der Gesetze verantwortlich
        und dürfen die Plattform oder die Dienstleistung nur in Übereinstimmung
        mit den geltenden Gesetzen nutzen. Wenn Sie sich nicht sicher sind, wie
        Sie die Gesetze einhalten sollen, sollten Sie sich bezüglich des Listings
        oder Buchung eines Angebotes oder Produktes rechtlich beraten lassen.
        <br/><br/>
        1.4 Einzug von Zahlungen, Gebühren & Steuern
        <br/><br/>
        Dorfplatz24 ist eine Plattform, die ausschliesslich Konsumenten/Kunden hilft,
        Produkte und Angebote  zu entdecken, und den Anbietern hilft, mit
        Konsumenten/Kunden zu kommunizieren.
        <br/><br/>
        1.5 Kommunikation und Hinweise
        <br/><br/>
        Wir können mit Ihnen kommunizieren und Ihnen Informationen oder Hinweise
        zu Ihrem Konto oder Ihren Transaktionen per E-Mail oder durch Nachrichten
        auf der Plattform zur Verfügung stellen. Sie werden umgehend auf alle
        Mitteilungen reagieren, die Sie erhalten, und verstehen, dass es sich auf
        Ihre Fähigkeit auswirken kann, Angebote zu buchen oder aufzulisten oder
        die Plattform oder die Dienstleistungen zu nutzen, wenn Sie dem nicht nachkommen.
        <br/><br/>
        Möglicherweise senden wir Ihnen Benachrichtigungen an die in Ihrem Konto
        angegebene E-Mail-Adresse oder Anschrift über das Nachrichtensystem auf
        der Plattform. Sie können uns unter support@Dorfpaltz24.com kontaktieren.
        Sie erklären sich damit einverstanden, dass jede E-Mail-Benachrichtigung
        von uns einen Tag nach dem Absenden als von Ihnen akzeptiert gilt und
        dieselbe rechtliche Wirkung hat, als ob sie Ihnen physisch zugestellt würde.
        <br/><br/>
        Sie erklären sich damit einverstanden, Mitteilungen von uns zu erhalten
        und elektronisch mit uns zu handeln. Diese Annahme von E-Mails oder anderen
        elektronischen Nachrichten stellt Ihre Zustimmung dar und Ihre elektronische
        Unterschrift hat die gleiche rechtliche Wirkung wie die physische Unterzeichnung
        eines Dokuments. Sie können diese Einwilligung zur elektronischen Transaktion
        jederzeit widerrufen, indem Sie uns dies mitteilen. Da die elektronische
        Kommunikation jedoch ein wesentlicher Bestandteil der Plattform und der Dienstleistungen
        ist, können wir nach einer solchen Mitteilung Ihr Konto schliessen.
        <br/><br/>
        Wenn Sie Probleme beim Kommunizieren von Nachrichten haben, kontaktieren
        Sie uns bitte zuerst unter support@Dorfplatz24.com.
        <br/><br/>
        1.6 Kommunikation mit anderen Benutzern
        <br/><br/>
        Über die Plattform können Sie mit anderen Benutzern kommunizieren, ohne
        vertrauliche persönliche Kontaktinformationen preiszugeben. Es liegt in
        Ihrer Verantwortung, die Informationen, die Sie anderen Benutzern zur Verfügung
        stellen, mit gutem Urteilsvermögen einzuschätzen. Sie dürfen die Plattform
        nur verwenden, um Produkte, Angebote oder Dienstleistungen aufzulisten oder
        zu buchen, mit uns oder anderen Benutzern zu kommunizieren, Streitigkeiten
        beizulegen oder andere Funktionen zu nutzen, die wir Ihnen über die Plattform
        zur Verfügung stellen. Sie dürfen die Plattform nicht verwenden, um unerwünschte
        oder nicht mit einem Listing oder einer Buchung verbundene Nachrichten über
        die Plattform zu senden, um andere Benutzer zu belästigen oder andere Dienstleistungen
        zu vermarkten oder um Spam zu senden.
        <br/><br/>
        Wir empfehlen dringend, dass Sie die Plattform verwenden, um mit anderen
        Benutzern zu kommunizieren. Wenn Sie andere Kommunikationsmittel verwenden,
        verstehen Sie, dass Sie Ihre persönlichen Kontaktinformationen möglicherweise
        einem Missbrauchsrisiko aussetzen. Sie verstehen auch, dass jegliche Kommunikation
        ausserhalb der Plattform Ihre Fähigkeit beeinträchtigen kann, im Falle eines
        Streits zwischen Ihnen und einem anderen Benutzer alle oder einige Ihnen
        geschuldete Beträge zurückzuerhalten.
        <br/><br/>
        Wir sind nicht verantwortlich für und lehnen jegliche Haftung ab, die sich
        aus Verlusten oder Schäden durch die Weitergabe persönlicher oder sensibler
        Informationen an andere Benutzer oder der Kommunikation oder Interaktion
        mit Benutzern ausserhalb der Plattform für Sie ergibt.
        <br/><br/>
        Dorfplatz24 kann nach eigenem Ermessen und ohne vorherige Ankündigung oder
        Verpflichtung von Zeit zu Zeit (i) die Kommunikationen zwischen Benutzern
        entfernen, die persönliche Kontaktinformationen enthalten oder teilen, oder
        (ii) die Konten von Benutzern, die persönliche Kontaktinformationen teilen,
        sperren oder kündigen.
      </p>

      <h2>Abschnitt 2: Plattform und Dienstleistung</h2>
      <p>
        2.1 Eigentum, Lizenz, Einschränkungen
        <br/><br/>
        Dorfplatz24 besitzt alle Rechte, Titel und Interessen an der Plattform,
        den Dienstleistungen und dem gesamten darin verkörperten oder enthaltenen
        geistigen Eigentum (einzeln und gemeinsam „GE“). GE umfasst alle eingetragenen
        oder potenziellen Patente, Urheberrechte, Marken, Geschäftsgeheimnisse und
        andere Eigentumsrechte. Über Ihr Konto gewähren wir Ihnen eine beschränkte,
        vorübergehende, widerrufliche, nicht übertragbare, nicht exklusive Lizenz
        zur Nutzung der Plattform und der Dienstleistung für die in dieser Vereinbarung
        beschriebenen Zwecke und nur so, wie sie Ihnen über die Plattform zur Verfügung
        gestellt werden. Diese Lizenz stellt keine Eigentumsübertragung dar und gewährt
        Ihnen keine zusätzlichen Rechte zur Nutzung des GE. Wir können Ihr Konto sperren
        oder schliessen und rechtliche Schritte gegen Sie einleiten, wenn wir der Ansicht
        sind oder feststellen, dass Ihre Nutzung der Plattform, der Dienstleistung
        oder des GE den Umfang dieser Bewilligung überschreitet; oder dass Sie versuchen,
        die Nutzung der Plattform, der Dienstleistung oder des GE durch andere zu hacken
        oder zu stören; oder dass Sie anderweitig den normalen Betrieb der Plattform
        oder der Dienstleistung hindern.
        <br/><br/>
        2.2 Community-Richtlinien
        <br/><br/>
        Wir haben Community-Richtlinien festgelegt, die unsere Erwartungen an alle
        Benutzer der Plattform festlegen. Sie werden die Community-Richtlinien
        überprüfen und einhalten, wenn Sie die Plattform oder Dienstleistungen nutzen,
        mit anderen Benutzern kommunizieren oder Produkte und Dienstleistungen anbieten.
        Wenn Sie glauben, dass ein anderer Benutzer gegen die Community-Richtlinien
        verstösst, senden Sie uns bitte eine E-Mail an support@Dorfplatz24.com.
        Dorfplatz24 ist nicht verpflichtet, die Einhaltung oder Durchsetzung
        der Community-Richtlinien durch Benutzer zu überwachen, und haftet nicht
        für Verstösse gegen die Community-Richtlinien durch Benutzer.
        <br/><br/>
        2.3 Inhalt
        <br/><br/>
        <b>(a) Veröffentlichung von Inhalten.</b> Sie versichern und garantieren,
        dass Sie berechtigt sind, Inhalte für die Plattform bereitzustellen, und
        dass von Ihnen bereitgestellte Inhalte nicht die Eigentums- oder Datenschutzrechte
        Dritter verletzen. Sie dürfen ohne deren ausdrückliche Genehmigung keine
        Inhalte bereitstellen, die von Dritten urheberrechtlich geschützt sind.
        Sie gewähren Dorfplatz24 eine vollständig bezahlte, weltweite, nicht exklusive,
        unbefristete Lizenz zur Nutzung, zum Kopieren, Übertragen, Verteilen, Ändern,
        öffentlichen Anzeigen und Unterlizenzieren von Inhalten, die Sie uns zur Verfügung
        stellen. Diese Bewilligung beinhaltet unsere Fähigkeit, Inhalte sowohl für
        den internen Gebrauch (z.B. zur Analyse der Plattform oder der Dienstleistungen)
        als auch für den externen Gebrauch (z.B. für Marketing oder Online-Werbung)
        zu verwenden. Wenn Sie uns die oben genannte Bewilligung nicht gewähren können,
        stellen Sie uns keine Inhalte zur Verfügung. Sie erklären sich damit einverstanden,
        uns von Schäden oder Verlusten freizustellen, zu verteidigen und schadlos
        zu halten, die auf Ansprüchen Dritter beruhen, wonach Inhalte Eigentums-
        oder Datenschutzrechte verletzen.
        <br/><br/>
        <b>(b) Einschränkungen für bestimmte Inhalte.</b> Sie dürfen niemals Inhalte
        veröffentlichen, die (i) diffamierend, obszön, profan oder pornografisch
        sind; (ii) andere Benutzer missbrauchen, belästigen oder respektlos behandeln;
        (iii) gegen geltende Gesetze verstossen, einschliesslich solcher, die Diskriminierung,
        falsche Werbung, Datenschutz oder rechtswidriges Marketing verbieten; (iv)
        täuschen oder irreführen, falsch oder ungenau sind oder die Art oder den Zustand
        eines Pferdes falsch darstellen; (v) Marketing- oder Werbeinhalte enthalten,
        die nicht mit den Details eines Pferdes zusammenhängen; oder (vi) sensible
        persönliche Informationen enthalten, einschliesslich Zahlungs- und Kontaktinformationen
        oder persönlicher Kontodaten.
        <br/><br/>
        <b>(c) DMCA-Mitteilungen.</b> Wenn Sie der Meinung sind, dass veröffentlichte
        Inhalte Ihr Urheberrecht verletzen, benachrichtigen Sie uns bitte unter
        support@Dorfplatz24.com. Dieser Hinweis sollte den spezifischen Inhalt
        identifizieren und uns den Nachweis Ihres Eigentums am Urheberrecht oder
        Ihrer Berechtigung zur Durchsetzung der Rechte des Urheberrechtsinhabers
        liefern. Alle Informationen, die Sie uns zur Verfügung stellen, können an
        andere Benutzer, Dritte oder Strafverfolgungsbehörden weitergegeben werden,
        um die Behauptung eines Verstosses zu beurteilen oder verletzende Inhalte
        zu entfernen. Wir werden alle Inhalte entfernen, von denen wir feststellen,
        dass sie mit unseren Verpflichtungen aus dem Digital Millennium Copyright
        Act (DMCA) verstossen.
        <br/><br/>
        Datenschutz und Datennutzung
        <br/><br/>
        Unsere Datenschutzrichtlinie beschreibt das Sammeln, Verwenden, Speichern
        und Weitergeben persönlicher Daten. Dies schliesst persönliche Informationen
        ein, die in Inhalten enthalten sind, sowie Informationen, die durch die Nutzung
        der Plattform gesammelt werden. Wir können Informationen, einschliesslich
        persönlicher Informationen oder Inhalte, anonymisieren, pseudonymisieren
        oder aggregieren und diese Informationen für jeden Zweck verwenden, einschliesslich
        der Verbesserung der Plattform oder Dienstleistungen oder der Erstellung
        oder Verteilung von öffentlichen Marketingmaterialie
      </p>

      <h2>Abschnitt 3: Buchung (findet hier keine Anwendung)</h2>

      <h2>Abschnitt 4: Kündigung, Streitigkeiten und verbindliche Schiedsverfahren</h2>
      <p>
        4.1 Laufzeit, Kündigung und Bestand
        <br/><br/>
        <b>(a) Laufzeit und Kündigung durch den Benutzer.</b> Sie stimmen dieser
        Vereinbarung zu, wenn Sie zum ersten Mal auf die Plattform oder die Dienstleistungen
        zugreifen oder diese nutzen, und Ihre fortgesetzte Nutzung der Plattform
        und der Dienstleistungen stellt Ihre fortlaufende Zustimmung zu dieser
        Vereinbarung in der jeweils gültigen Fassung dar. Sie können diese Vereinbarung
        kündigen, indem Sie Ihr Konto bei uns schliessen, dadurch werden jedoch
        laufende Rechte oder Pflichten, die Sie oder wir möglicherweise haben,
        nicht sofort gekündigt. Dies schliesst alle Verbindlichkeiten ein, die
        Sie vor der Kündigung eingegangen sind.
        <br/><br/>
        <b>(b) Suspendierung oder Beendigung durch Dorfplatz24.</b> Wir können Ihr
        Konto --einschliesslich Ihrer Fähigkeit, mit anderen Benutzern zu kommunizieren
        oder eine Buchung abzuschliessen -- jederzeit sperren oder diese Vereinbarung
        und Ihr Konto jederzeit kündigen, einschliesslich, ohne Einschränkung,
        (i) wenn wir der Ansicht sind, dass die Nutzung Ihres Kontos ein Risiko für
        Dorfplatz24, Sie, andere Benutzer oder Dritte, darstellt, (ii) bei tatsächlichem
        oder potenziellem Betrug durch Sie oder in Ihrem Namen, (iii) bei Nichtbeantwortung
        Ihrerseits auf Mitteilungen von uns oder anderen Benutzern oder (iv) bei
        Nichtbeachtung dieser Vereinbarung oder geltendem Recht Ihrerseits.
        <br/><br/>
        <b>(c) Bestand.</b> Die folgenden Bestimmungen bleiben auch nach Beendigung
        dieser Vereinbarung bestehen: Abschnitte 1.3 (Einhaltung von Gesetzen),
        1.4 (Gebühren und Steuern), 1.5 (Kommunikation und Hinweise), 1.6 (Kommunikation
        mit anderen Benutzern), 2.1 (Eigentum, Lizenz, Einschränkungen), 2.3 (Inhalt),
        2.4 (Datenschutz und Datennutzung), Abschnitt 3.1 (Verhalten und Gebühren)
        für Pferdebesitzer, Abschnitt 3.2(b) (Verhalten und Gebühren) für Reiter,
        3.3 (Erforderliche und Zusätzliche Versicherung), 3.4 (Stornierungen und
        Rückerstattungen), 4 (Kündigung, Streitigkeiten und verbindliche Schiedsverfahren)
        und Abschnitt 5 (Zusätzliche rechtliche Bestimmungen).
        <br/><br/>
        4.2 Verbindliche Schiedsverfahren
        <br/><br/>
        In diesem Abschnitt wird beschrieben, wie Streitigkeiten oder Ansprüche
        aus dieser Vereinbarung zwischen Ihnen und HorseDeal24 oder zwischen Ihnen
        und einem anderen Benutzer (die nicht durch den in Abschnitt 4.3 beschriebenen
        Prozess beigelegt werden) beigelegt werden. Dies beinhaltet den Verzicht
        auf ein Gerichtsverfahren und Ihre Fähigkeit, sich anderen Klägern im Rahmen
        einer Sammelklage anzuschliesen. Bitte lesen Sie diesen Abschnitt sorgfältig
        durch, bevor Sie diese Vereinbarung akzeptieren—Sie dürfen die Plattform
        oder die Dienstleistungen nicht nutzen, wenn Sie diesem Abschnitt nicht zustimmen.
        <br/><br/>
        <b>(a) Verfahren der Schiedsverfahren.</b> Vorbehaltlich der in Abschnitt
        4.2(b) angegebenen Ausschlüsse und des in Abschnitt 4.3 vorgesehenen Verfahrens
        werden alle Streitigkeiten, Ansprüche und Kontroversen, die sich aus oder
        im Zusammenhang mit dieser Vereinbarung zwischen Ihnen und HorseDeal24 oder
        zwischen Ihnen und einem anderen Benutzer ergeben (sofern diese nicht gemäss
        Abschnitt 4.3 beigelegt wurden) durch ein verbindliches Schiedsverfahren
        wie folgt gelöst:
        <br/>
        <ul style={{listStyleType: 'disc'}}>
          <li style={{fontSize: '16px'}}>
            (i) wenn der Betrag der Streitigkeit, des Anspruchs oder der Kontroverse
            vernünftigerweise weniger als 25.000 Schweizer Franken beträgt, wird
            die Lösung online von FairClaims (www.fairclaims.com) oder einem anderen
            Online-Schlichtungsanbieter unserer Wahl gemäss den geltenden Schiedsordnung
            und Verfahren verwaltet, die zum Zeitpunkt der Geltendmachung eines
            Anspruchs wirksam sind. Sie erklären sich damit einverstanden, den
            elektronischen Prozessdienst per E-Mail zu erhalten, die Ihrem Konto
            zugeordnet ist. Wenn Sie die Beantwortung eines solchen Prozesses versäumen,
            sind Sie für alle mit dem Vergehen verbundenen Anwalts-, Gerichts- oder
            sonstigen Gebühren verantwortlich. Die Partei, die den Nutzerstreitfall
            einreicht, ist für die Zahlung aller mit dieser Einreichung verbundenen
            Kosten verantwortlich, einschliesslich der von HorseDeal24 getragenen Kosten.
            Im Rahmen des Nutzerstreitfalls können Sie auch versuchen, diese Kosten
            zu erstatten, wenn Sie sich durchsetzen.
          </li>
          <br/>
          <li style={{fontSize: '16px'}}>
            (ii) wenn der Betrag der Streitigkeit, des Anspruchs oder der Kontroverse
            vernünftigerweise 25.000 Schweizer Franken oder mehr beträgt, muss die
            Lösung vor einem einzelnen Vermittler erfolgen. Dies umfasst, ist jedoch
            nicht beschränkt auf, gesetzliche oder gewohnheitsrechtliche Ansprüche
            in Bezug auf Verstösse, Durchsetzung oder Auslegung dieser Vereinbarung
            und jeglicher Buchungsvereinbarung. Ein solches Schiedsverfahren findet
            in dem Landkreis statt, in der sich das gebuchte oder gelistete Pferd
            befindet, sofern Sie und HorseDeal24 nichts anderes vereinbaren. Der
            Vermittler wendet das Schweizer Recht an. Um ein solches Schiedsverfahren
            einzuleiten, wird eine Partei eine schriftliche Aufforderung stellen,
            in der sowohl die Grundlage des Anspruchs als auch die gewünschte Rechtshilfe
            angegeben sind. Jede Partei erklärt sich unwiderruflich und bedingungslos
            damit einverstanden, sich der gerichtlichen Zustellung durch Zustellung
            einer Vorladung in ihrer Unternehmenszentrale, registrierten Adresse
            oder primären Adresse (für Einzelpersonen oder Einzelunternehmer) zu
            bedienen. Nichts in dieser Vereinbarung berührt das Recht einer Partei,
            den Prozess auf eine andere gesetzlich zulässige Weise zu bedienen.
          </li>
        </ul>
        Sobald ein Schiedsverfahren gemäss den oben beschriebenen Unterabschnitten
        (i) oder (ii) eingeleitet wurde, teilen sich die Parteien die Kosten des
        Schiedsverfahrens, der Einrichtungen und der Referenten des Schiedsverfahrens
        (falls erforderlich) zu gleichen Teilen, sofern der Richter am Schiedsgericht
        nichts anderes bestimmt. Jede Partei trägt die eigenen Anwalts- und Rechtskosten.
        Der Richter am Schiedsgericht kann der obsiegenden Partei die Erstattung
        von Kosten für Schiedsverfahren oder Rechtskosten nach eigenem Ermessen zuweisen.
        <br/><br/>
        Der Richter am Schiedsgericht kann finanzielle oder andere Rechtsmittel
        bereitstellen, die nach geltendem Recht verfügbar sind, die Bestimmungen
        dieser Vereinbarung oder einer Buchungsvereinbarung jedoch nicht ändern.
        Der Richter am Schiedsgericht wird eine begründete Entscheidung treffen,
        die sich mit den Einzelheiten des Rechtsstreits befasst. Die Entscheidung
        ist bindend und kann nicht angefochten werden. Die Parteien werden unverzüglich
        handeln, um die Entscheidung des Richters zu respektieren, einschliesslich
        Zahlung der geschuldeten Beträge oder der Ergreifung erforderlicher Massnahmen.
        <br/><br/>
        Ein Urteil über den Schiedsspruch des Richters kann bei jedem zuständigen
        Gericht eingereicht werden.
        <br/><br/>
        <b>(b) Bestimmte Ansprüche ausgeschlossen.</b> Ungeachtet des Abschnitts
        4.2(a) vereinbaren die Parteien, dass Ansprüche aufgrund des Eigentums oder
        des Missbrauchs von geistigem Eigentum der anderen Partei—einschliesslich
        Patenten, Urheberrechten oder Marken—vor den Landes- oder Bundesgerichten
        in der Schweiz geltend gemacht werden können. Jede Partei kann auch vorläufige
        Rechtsbehelfe für Unterlassungsansprüche aufgrund solcher Ansprüche bei einem
        zuständigen Gericht beantragen.
        <br/><br/>
        (c) Sammelklage und Verzicht der Jury. Jede Partei erklärt sich damit einverstanden,
        dass Klagen oder Ansprüche aus oder im Zusammenhang mit dieser Vereinbarung
        oder einer Buchungsvereinbarung nur auf individueller Basis und nicht Teil
        einer Sammelklage oder eines konsolidierten Schiedsverfahrens erhoben werden
        können oder Ansprüche mit anderen Benutzern oder Dritten verbinden. Ferner
        verzichtet jede Partei ausdrücklich auf das Recht einer Jury im Schiedsverfahren
        und Gericht, sofern dies zulässig ist. Sie können diesen in Abschnitt 4.2(c)
        beschriebenen Verzicht bezüglich Sammelklage und Jury verweigern, indem Sie
        uns innerhalb von 30 Tagen nach Ihrer ersten Nutzung der Plattform oder
        der Dienstleistungen eine E-Mail an support@horsedeal24.com senden. Sie
        müssen Ihren Namen, Ihre Telefonnummer, Ihre physische Adresse und Ihre
        E-Mail-Adresse in Ihre Verweigerungsmitteilung beifügen. Dies ist Ihr einziger
        Mechanismus, um diesen Abschnitt 4.2(c) zu verweigern und wenn Sie dies
        nicht wie beschrieben tun, stimmen Sie diesem Verzicht zu. Wenn Sie sich
        gegen diesen Abschnitt 4.2(c) entscheiden, beachten Sie bitte, dass alle
        anderen Bestimmungen dieser Vereinbarung intakt und in vollem Umfang in
        Kraft und wirksam bleiben.
        <br/><br/>
        (d) Regelkonflikt. Sollte sich herausstellen, dass eine Bestimmung dieses
        Abschnitts 4.2 ungültig oder nicht durchsetzbar ist, wird das überprüfende
        Gericht oder der Richter am Schiedsgericht die Bestimmungen gegebenenfalls
        nur so wenig auslegen oder überarbeiten, wie dies zur Einhaltung des Gesetzes
        erforderlich ist. Alle anderen Bestimmungen bleiben wie geschrieben durchsetzbar
        und intakt.
        <br/><br/>
        Streitigkeiten zwischen Benutzern
        <br/><br/>
        In diesem Abschnitt wird beschrieben, wie Streitigkeiten oder Ansprüche
        aus dieser Vereinbarung zwischen Ihnen und einem anderen Benutzer beigelegt
        werden. Bitte lesen Sie diesen Abschnitt sorgfältig durch, bevor Sie diese
        Vereinbarung akzeptieren—Sie dürfen die Plattform oder die Dienstleistungen
        nicht nutzen, wenn Sie diesem Abschnitt nicht zustimmen.
        <br/><br/>
        <b>(a) Anfängliche Beilegung von Streitigkeiten zwischen Benutzern.</b>
        Sie erklären sich damit einverstanden, zunächst zu versuchen, Streitigkeiten,
        Unstimmigkeiten oder Ansprüche, die Sie mit anderen Benutzern haben („Nutzerstreitfall“),
        nach Treu und Glauben über die Plattform beizulegen. Wenn Sie den Nutzerstreitfall
        nicht lösen können, senden Sie den Nutzerstreitfall an support@Dorfplatz24.com.
        Nach unserem alleinigen Ermessen können wir (i) verlangen, dass Sie zusätzliche
        Details zu Nutzerstreitfällen einreichen. Wir werden die Zusammenfassung und
        die auf der Plattform getätigten Mitteilungen überprüfen. Wir können, sind
        aber nicht verpflichtet, auch Mitteilungen ausserhalb der Plattform zu überprüfen.
        Nach Überprüfung und Untersuchung werden wir entweder (a) Ihnen und dem anderen
        Benutzer unsere Schlussfolgerung auf der Grundlage der bereitgestellten
        Zusammenfassung übermitteln, die Sie als endgültige und verbindliche Entscheidung
        mit der gleichen Kraft und Wirkung akzeptieren, als ob sie durch ein Schiedsverfahren
        gemäss Abschnitt 4.2 bestimmt worden sei; oder (b) verlangen, dass der Streit
        durch ein verbindliches Schiedsverfahren beigelegt wird, das von einem Dritten
        gemäss Abschnitt 4.2(a) entschieden wird. Die Partei, die den Nutzerstreitfall
        einreicht, ist für die Zahlung aller mit dieser Einreichung verbundenen Kosten
        verantwortlich, einschliesslich der von Dorfplatz24 getragenen Kosten. Im
        Rahmen des Nutzerstreitfalls können Sie auch versuchen, diese Kosten zu erstatten,
        wenn Sie sich durchsetzen.
        <br/><br/>
        <b>(b) Nutzerstreitfälle unter 25.000 Schweizer Franken.</b> Wenn Sie sich
        nach dem oben beschriebenen Verfahren auf einen Betrag von vernünftigerweise
        weniger als 25.000 Schweizer Franken beziehen, erklären Sie sich damit
        einverstanden, den Nutzerstreitfall einem verbindlichen Schiedsverfahren
        gemäss Abschnitt 4.2(a)(i) zu unterziehen.
        <br/><br/>
        <b>(c) Nutzerstreitfälle von 25.000 Schweizer Franken oder höher.</b>
        Wenn Sie sich nach dem oben beschriebenen Verfahren auf einen Betrag von
        vernünftigerweise mindestens 25.000 Schweizer Franken beziehen, erklären
        Sie sich damit einverstanden, den Nutzerstreitfall einem verbindlichen
        Schiedsverfahren gemäss Abschnitt 4.2(a)(ii) zu unterziehen.
        <br/><br/>
        4.4 Vertraulichkeit des Verfahrens
        <br/><br/>
        Alle Verfahren gemäss diesem Abschnitt 4 und ihre Ergebnisse werden von
        allen Parteien vertraulich behandelt. Sofern gesetzlich nicht anders vorgeschrieben,
        werden die Parteien und die Personen, die in ihrem Namen an dem Verfahren
        teilnehmen, die während des Verfahrens bereitgestellten Materialien, Zeugnisse
        und Beweise sowie die Ergebnisse dieses Verfahrens nicht offenlegen und
        die Vertraulichkeit wahren. Die Parteien vereinbaren, gegebenenfalls eine
        gesonderte Vertraulichkeitsvereinbarung oder Anordnung zur Wahrung der
        Vertraulichkeit des Verfahrens abzuschliessen.
      </p>

      <h2>Abschnitt 5: Zusätzliche rechtliche Bestimmungen</h2>
      <p>
        5.1 Recht zur Ergänzung
        <br/><br/>
        Wir können diese Vereinbarung jederzeit ergänzen oder ändern, indem wir die
        abgeänderte Vereinbarung auf unserer Website veröffentlichen, Ihnen eine
        Kopie per E-Mail senden oder Ihnen die Änderung auf andere Weise über die
        Plattform mitteilen. Ihre fortgesetzte Nutzung der Plattform oder der
        Dienstleistungen nach Ergänzung oder Änderung dieser Vereinbarung stellt
        Ihre Zustimmung zu der überarbeiteten Vereinbarung dar.
        <br/><br/>
        5.2 Keine Abtretung
        <br/><br/>
        Ohne unsere vorherige schriftliche Zustimmung dürfen Sie diese Vereinbarung
        oder Ihnen gewährte Rechte, einschliesslich des Betriebs oder der Verwaltung
        Ihres Kontos, nicht abtreten. Jeder Versuch, dies ohne unsere vorherige
        Zustimmung zu tun, ist nichtig. Wir können diese Vereinbarung nach Benachrichtigung
        an Sie im Rahmen eines Verkaufs oder einer Übertragung eines Teils oder
        unseres gesamten Geschäfts abtreten. Jede erlaubte Übertragung kommt den
        Rechtsnachfolgern zugute und bindet sie.
        <br/><br/>
        Abschlussklauseln
        <br/><br/>
        Dorfplatz24 bietet die Nachrichtenplattform und die Dienstleistung, um Produkte,
        Angebote und Dienstleistungen zu buchen und direkt mit anderen Benutzern
        zu kommunizieren. Wir unterstützen keine Verträge zwischen Konsumenten/Kunden
        und Produzenten für Buchungen oder Dienstleistungen, sammeln keine Zahlungen
        oder Gebühren oder sind weder dafür verantwortlich noch haften wir dafür.
        Wir sind weder Ihnen noch Dritten gegenüber für die Bereitstellung oder
        den Zustand von Produkten, Angeboten oder Dienstleistungen verantwortlich.
        Produzenten, Konsumenten/Kunden und Dienstleister sind unabhängige Dritte
        und stehen nicht in Verbindung mit Dorfplatz24 und werden von Dorfplatz24
        weder kontrolliert noch beschäftigt. Produzenten legen ihre eigenen Preise
        fest, nutzen ihre eigenen Einrichtungen und Ressourcen und können den Nutzen
        von Angeboten und Dienstleistungen bestimmen, wie sie für sie geeignet sind.
        <br/><br/>
        Sie verstehen und stimmen zu, dass die Nutzung der Plattform und der Dienstleistungen
        auf eigenes Risiko erfolgt. Dorfplatz24 ist nicht für die Durchführung verantwortlich
        und führt keine Hintergrundüberprüfungen bei Konsumenten/Kunden, Anbietern
        oder Dienstleistern (einschliesslich Straf- oder Zivilkontrollen) durch;
        überprüft nicht den Zustand von Angeboten oder stellt fest, dass sie das
        geltende Recht einhalten, spezifische Anforderungen erfüllen oder ihrer
        Beschreibung entsprechen; und garantiert nicht die Leistung eines Benutzers
        oder eines Dritten. Jegliche Informationen, die Ihnen in Bezug auf Konsumenten/Kunden,
        Produzenten oder Dienstleister zur Verfügung gestellt werden, werden nur
        für die Vermittlung ihrer Nutzung der Plattform zur Verfügung gestellt ohne
        jegliche Zusicherung oder Gewährleistung. Dorfplatz24 schliesst jegliche
        ausdrücklichen und stillschweigenden Zusicherungen und Gewährleistungen aus,
        dass jegliche auf der Plattform zur Verfügung gestellten Informationen genau
        oder vollständig sind oder dass jegliche Konsumenten/Kunden, Produzenten
        oder Dienstleister Ihnen wie in der Beschreibung beschrieben zur Verfügung
        gestellt werden. Dorfplatz24 schliesst jegliche Verantwortung und Verbindlichkeit
        aus, die aus Nachlässigkeit, absichtlichem Fehlverhalten oder kriminellen
        Aktivitäten aller Benutzer oder Dritter resultiert sowie jegliche Verletzungen
        oder Eigentumsbeschädigungen, die Ihnen, Dritten oder Eigentum durch Nutzung
        von Produkten, Angeboten und Dienstleistungen wiederfahren.
        <br/><br/>
        Die Plattform oder die Dienstleistungen werden Ihnen „wie gesehen“ und
        „wie verfügbar“ ohne jegliche ausdrückliche oder stillschweigende Garantie
        oder Gewährleistung jeglicher Art zur Verfügung gestellt, einschliesslich,
        jedoch nicht beschränkt auf, Gewährleistungen für die Marktgängigkeit, die
        Eignung für einen bestimmten Zweck und die Tauglichkeit für Ihre bestimmte
        Verwendung, oder Nichtverletzung. Ohne das Vorstehende einzuschränken, lehnen
        wir ab, dass die Plattform oder die Dienstleistungen frei von Fehlern oder
        Viren sind; dass sie niemals Schäden verursachen werden; dass sie Ihre
        Bedürfnisse oder Anforderungen erfüllen oder dafür geeignet sind; dass sie
        immer verfügbar sind; oder dass sie Angebote oder Dienstleistungen genau
        vertreten. Wir lehnen ausdrücklich jegliche Verpflichtungen zur Behebung
        von Fehlern ab, auch wenn wir uns diesen bewusst sind, und können uns jederzeit
        dafür entscheiden, den Betrieb der Plattform oder Dienstleistung oder Funktionen
        jeglicher Art einzustellen.
        <br/><br/>
        5.4 Zusicherungen und Gewährleistungen
        <br/><br/>
        Durch die Eröffnung Ihres Kontos erklären Sie und garantieren, dass (a) Sie
        zur Nutzung der Plattform und Dienstleistungen berechtigt sind und zur Ausführung
        dieser Vereinbarung befugt sind; (b) Sie nur ein Konto bei uns haben und
        noch kein Konto von Ihnen durch uns gekündigt wurde; (c) alle uns zur Verfügung
        gestellten Informationen korrekt und vollständig sind; (d) Sie die Plattform
        oder Dienstleistungen nicht unter Verstoss gegen das Gesetz oder der Community-Richtlinien
        verwenden werden, um Betrug zu begehen, andere Benutzer zu täuschen oder
        für einen anderen unangemessenen Zweck; und (e) Sie berechtigt sind, uns
        Inhalte zur Verfügung zu stellen.
        <br/><br/>
        Wenn Sie die Plattform oder Dienstleistungen als Produzent nutzen, versichern
        Sie ferner, dass (f) Sie gemäss den geltenden Gesetzen berechtigt sind, alle
        bereitgestellten Angebote aufzulisten; (g) Ihr Listing eines Angebotes und
        der Tisch selbst den geltenden Gesetzen entsprechen; und (h) Sie die Nutzung
        der von Konsumenten/Kunden gebuchten Angeboten angemessen erleichtern und
        die Nutzung nicht behindern werden.
        <br/><br/>
        Wenn Sie die Plattform oder Dienstleistungen als Konsument/Kunde nutzen,
        versichern Sie ferner, dass (i) Sie alle Buchungsvereinbarungen einhalten
        werden; (j) Sie Angebote oder Dienstleistungen nicht in einer Weise nutzen
        werden, die gegen Gesetze oder Community-Richtlinien verstösst oder die Verletzung
        durch Dritte erleichtert; und (k) alle uns zur Verfügung gestellten Zahlungsinformationen
        wie in dieser Vereinbarung beschrieben verwendet werden können.
        <br/><br/>
        5.5 Schadenersatz
        <br/><br/>
        Sie halten Dorfplatz24 und seine Eigentümer, Vertreter, Mitglieder, Informationsanbieter,
        Anwälte oder verbundenen Unternehmen (zusammen „Dorfplatz24-Vertragspartner“)
        von allen Ansprüchen, Verbindlichkeiten, Verlusten, Schäden und damit verbundenen
        Kosten schadlos (einschlieslich angemessener Rechtskosten) (zusammen „Ansprüche“),
        die sich ergeben aus oder im Zusammenhang mit (a) der Bereitstellung Ihres
        Angebotes (für Produzenten) oder Dienstleistungen (für Produzenten) oder
        der Nutzung von Angeboten oder Dienstleistungen (für Konsumenten/Kunden);
        (b) Inhalten, die Sie über die Plattform bereitstellen; (c) Ihrer Nichteinhaltung
        von Gesetzen; (d) Ihrer ungenauen oder unvollständigen Übermittlung von Informationen
        an uns, (e) Ihrer Verletzung einer Ihrer Verpflichtungen aus dieser Vereinbarung
        und (f) eines Vertrags oder einer anderen Vereinbarung zwischen Ihnen und
        einem anderen Benutzer als über die Plattform. Diese Entschädigung bleibt
        auch nach Beendigung dieser Vereinbarung bestehen.
        <br/><br/>
        5.6 Haftungsbeschränkungen
        <br/><br/>
        Mit Ausnahme des begrenzten Umfangs der Dorfplatz24-Gebühren (wie unten festgelegt)
        wird Dorfplatz24 oder dessen Eigentümer, Vertreter, Mitglieder, Informationsanbieter,
        Anwälte oder Geschäftspartner unter keinen Umständen oder einer rechtlichen
        Theorie für Sie oder Dritte haftbar sein im Falle von Schäden (ob direkt,
        indirekt, allgemein, kompensierend, nebensächlich, folgend, speziell, strafend,
        beispielhaft oder anderweitig), Verletzung, Anspruch oder Haftung jeglicher
        Art oder basierend auf oder sich ergebend aus, Ihrer Nutzung oder Nutzungsunfähigkeit
        der Plattform oder Dienstleistungen, oder Angeboten, auch wenn wir auf die Möglichkeit
        von Schäden hingewiesen wurden. Wenn Sie mit der Plattform oder Dienstleistungen
        oder den darin enthaltenen Inhalten nicht zufrieden sind, besteht Ihre einzige
        und ausschliessliche Abhilfe darin, die Nutzung der Plattform und Dienstleistungen
        einzustellen.
        <br/><br/>
        Wie hierin verwendet, bedeuten „Dorfplatz24-Gebühren“ den von Ihnen an Dorfplatz24
        gezahlten Betrag, ausschliessend Beträge, die für Angebote oder Dienstleistungen
        bezahlt wurden oder zahlbar sind, für die Nutzung der Plattform oder Dienstleistungen.
        <br/><br/>
        Die oben festgelegte Haftungsbeschränkung gilt in dem gesetzlich zulässigen Umfang.
        <br/><br/>
        5.7 Zusätzliche Bedingungen für Benutzer ausserhalb der Schweiz
        <br/><br/>
        Wir können die Nutzung der Dienstleistung zur Auflistung von Produkten und
        Angeboten ausserhalb der Schweiz zulassen. Wo dies zulässig ist, müssen
        Sie sicherstellen, dass Ihre Nutzung der Dienstleistung, einschliesslich
        Auflistung oder Buchung, allen für Ihr Land, Ihre Provinz oder Region spezifischen
        Gesetzen entspricht. Europäische Benutzer müssen möglicherweise Informationen
        bereitstellen, um die Erhebung von Mehrwertsteuern oder anderen indirekten
        Steuern zu unterstützen, oder Nachweise für Ihre Befreiung von diesen Steuern.
        <br/><br/>
        5.8 Gesamte Vereinbarung; Auslegung
        <br/><br/>
        Diese Vereinbarung stellt zusammen mit jeder Buchungsvereinbarung die gesamte
        Vereinbarung zwischen Ihnen und Dorfplatz24 dar, die Ihre Nutzung der Plattform
        oder der Dienstleistungen regelt. Diese Vereinbarung ersetzt alle vorherigen
        Absprachen oder Vereinbarungen zwischen Ihnen und Dorfplatz24. Zwischen Ihnen
        und Dorfplatz24 regelt diese Vereinbarung alle widersprüchlichen Bestimmungen
        in einer Buchungsvereinbarung, sofern nicht ausdrücklich anders angegeben
        und zwischen den Parteien schriftlich vereinbart.
        <br/><br/>
        Alle in dieser Vereinbarung beschriebenen Geldbeträge werden in Schweizer
        Franken angegeben, und „CHF“ bedeutet Schweizer Franken.
      </p>

      <h2></h2>
      <p>

      </p>

      <h2></h2>
      <p>

      </p>

    </div>
  );
};

TermsOfService.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

TermsOfService.propTypes = {
  rootClassName: string,
  className: string,
};

export default TermsOfService;
