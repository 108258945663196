import React from 'react';
import { required } from '../../util/validators';
import { FieldSelect } from '../../components';

import css from './EditListingDescriptionForm.css';

const CustomUnitTypeSelectFieldMaybe = props => {
  const { name, id, unitTypes, intl } = props;
  const unitTypeLabel = intl.formatMessage({
    id: 'EditListingDescriptionForm.unitTypeLabel',
  });
  const unitTypePlaceholder = intl.formatMessage({
    id: 'EditListingDescriptionForm.unitTypePlaceholder',
  });
  const unitTypeRequired = required(
    intl.formatMessage({
      id: 'EditListingDescriptionForm.unitTypeRequired',
    })
  );
  return unitTypes ? (
    <FieldSelect
      className={css.category}
      name={name}
      id={id}
      label={unitTypeLabel}
      validate={unitTypeRequired}
    >
      <option disabled value="">
        {unitTypePlaceholder}
      </option>
      {unitTypes.map(c => (
        <option key={c.key} value={c.key}>
          {c.label}
        </option>
      ))}
    </FieldSelect>
  ) : null;
};

export default CustomUnitTypeSelectFieldMaybe;
