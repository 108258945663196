import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { PropertyGroup } from '../../components';

import css from './ListingPage.css';

const SectionShoeSizesMaybe = props => {
  const { options, publicData } = props;
  if (!publicData) {
    return null;
  }

  const selectedOptions = publicData && publicData.shoeSizes ? publicData.shoeSizes : [];
  return selectedOptions.length > 0 ? (
    <div className={css.sectionFeatures}>
      <h2 className={css.featuresTitle}>
        <FormattedMessage id="ListingPage.shoeSizesTitle" />
      </h2>
      <PropertyGroup
        id="ListingPage.shoeSizes"
        options={options}
        selectedOptions={selectedOptions}
        twoColumns={true}
      />
    </div>
  ) : null;
};

export default SectionShoeSizesMaybe;
