import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';

import { NamedLink } from '../../components';

import css from './SectionProducts.css';

import category1Image from './images/category1_lebensmittel.jpg';
import category2Image from './images/category2_gesund_n_schon.jpg';
import category3Image from './images/category3_essen_n_geniessen.jpg';

class LocationImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
const LazyImage = lazyLoadWithDimensions(LocationImage);

const locationLink = (name, image, translationId, searchQuery) => {
  const nameText = <span className={css.locationName}>{name}</span>;
  return (
    <NamedLink name="SearchPage" to={{ search: searchQuery }} className={css.location}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          <LazyImage src={image} alt={name} className={css.locationImage} />
        </div>
      </div>
      <div className={css.linkText}>
        <FormattedMessage
          id={translationId}
          values={{ category: nameText }}
        />
      </div>
    </NamedLink>
  );
};

const SectionProducts = props => {
  const { rootClassName, className } = props;

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.title}>
        <FormattedMessage id="SectionLocations.title" />
      </div>
      <div className={css.locations}>
        {locationLink(
          'Gewerbe & Geschäfte',
          category1Image,
          "SectionProducts.labelCategory1",
          '?address=Schweiz&bounds=47.8084549866365%2C10.4920638273824%2C45.8180770356515%2C5.95599426104971&pub_category=gewerbe_n_geschafte'
        )}
        {locationLink(
          'Gesund & Schön',
          category2Image,
          "SectionProducts.labelCategory2",
          '?address=Schweiz&bounds=47.8084549866365%2C10.4920638273824%2C45.8180770356515%2C5.95599426104971&pub_category=gesund_n_schon'
        )}
        {locationLink(
          'Essen & Geniessen',
          category3Image,
          "SectionProducts.labelCategory3",
          '?address=Schweiz&bounds=47.8084549866365%2C10.4920638273824%2C45.8180770356515%2C5.95599426104971&pub_category=essen_n_geniessen'
        )}
      </div>
    </div>
  );
};

SectionProducts.defaultProps = { rootClassName: null, className: null };

const { string } = PropTypes;

SectionProducts.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionProducts;
