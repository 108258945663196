import { updatedEntities, denormalisedEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { types as sdkTypes } from '../../util/sdkLoader';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const FETCH_FAVORITE_LISTINGS_REQUEST = 'app/ManageFavoriteListingsPage/FETCH_FAVORITE_LISTINGS_REQUEST';
export const FETCH_FAVORITE_LISTINGS_SUCCESS = 'app/ManageFavoriteListingsPage/FETCH_FAVORITE_LISTINGS_SUCCESS';
export const FETCH_FAVORITE_LISTINGS_ERROR = 'app/ManageFavoriteListingsPage/FETCH_FAVORITE_LISTINGS_ERROR';

export const ADD_OWN_ENTITIES = 'app/ManageFavoriteListingsPage/ADD_OWN_ENTITIES';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: false,
  queryListingsError: null,
  currentPageResultIds: [],
  ownEntities: {},
  openingListing: null,
  openingListingError: null,
  closingListing: null,
  closingListingError: null,
};

const resultIds = payload => payload.map(pE => pE.data.data.id);

const merge = (state, sdkResponse) => {
  const responseAr = sdkResponse.length > 0 ?
    sdkResponse.map( sdkResponseEntry => sdkResponseEntry.data.data ) : [];

  sdkResponse.forEach( sdkResponseEntry => {
    if(Array.isArray(sdkResponseEntry.data.included) && sdkResponseEntry.data.included.length > 0){
      responseAr.push(sdkResponseEntry.data.included[0]);
    }
  });

  const apiResponse = { data: responseAr };
  return {
    ...state,
    ownEntities: updatedEntities({ ...state.ownEntities }, apiResponse),
  };
};

const manageListingsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_FAVORITE_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_FAVORITE_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload),
        queryInProgress: false,
      };
    case FETCH_FAVORITE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, queryInProgress: false, queryListingsError: payload };

    case ADD_OWN_ENTITIES:
      return merge(state, payload);

    default:
      return state;
  }
};

export default manageListingsPageReducer;

// ================ Selectors ================ //

/**
 * Get the denormalised own listing entities with the given IDs
 *
 * @param {Object} state the full Redux store
 * @param {Array<UUID>} listingIds listing IDs to select from the store
 */
export const getOwnListingsById = (state, listingIds) => {
  const { ownEntities } = state.ManageFavoriteListingsPage;
  const resources = listingIds.map(id => ({
    id,
    type: 'listing',
  }));
  const throwIfNotFound = false;
  return denormalisedEntities(ownEntities, resources, throwIfNotFound);
};

// ================ Action creators ================ //

// This works the same way as addMarketplaceEntities,
// but we don't want to mix own listings with searched listings
// (own listings data contains different info - e.g. exact location etc.)
export const addOwnEntities = sdkResponse => ({
  type: ADD_OWN_ENTITIES,
  payload: sdkResponse,
});


export const queryFavoriteListingsRequest = queryParams => ({
  type: FETCH_FAVORITE_LISTINGS_REQUEST,
  payload: { queryParams },
});

export const queryFavoriteListingsSuccess = response => ({
  type: FETCH_FAVORITE_LISTINGS_SUCCESS,
  payload: response,
});

export const queryFavoriteListingsError = e => ({
  type: FETCH_FAVORITE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

// Throwing error for new (loadData may need that info)
export const queryFavoriteListings = () => (dispatch, getState, sdk) => {
  const currentUser = getState().user.currentUser;
  const favoriteListingIds =
    currentUser && currentUser.attributes.profile.privateData.favoriteListingIds ?
      currentUser.attributes.profile.privateData.favoriteListingIds : [];

    dispatch(queryFavoriteListingsRequest(favoriteListingIds));

    const showListingPromises =
      favoriteListingIds.map( id => sdk.listings.show({
        id: new UUID(id),
        include: ['images'],
        'fields.image': ['variants.landscape-crop', 'variants.landscape-crop2x'],
        'limit.images': 1,
      }));

  return Promise.all(showListingPromises)
    .then(response => {
      dispatch(addOwnEntities(response));
      dispatch(queryFavoriteListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(queryFavoriteListingsError(storableError(e)));
      throw e;
    });
};
