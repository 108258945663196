import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaYoutube,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import css from './Footer.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteYoutubePage } = config;

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToYoutube = intl.formatMessage({ id: 'Footer.goToYoutube' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const youtubeLink = siteYoutubePage ? (
    <ExternalLink key="linkToYoutube" href={siteYoutubePage} className={css.icon} title={goToYoutube}>
      <IconSocialMediaYoutube />
    </ExternalLink>
  ) : null;

  return [fbLink, instragramLink, youtubeLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  const locationLinks = (
    <>
      <div className={css.searches}>
        <ul className={css.list}>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Kanton%20Aargau%2C%20Schweiz&bounds=47.6207929797717%2C8.4551119931053%2C47.1374770517375%2C7.7135600138042',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchAargau" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Kanton%20Bern%2C%20Schweiz&bounds=47.3453208985378%2C8.45507699999278%2C46.326520005032%2C6.86149900115696',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchBern" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Kanton%20Freiburg%2C%20Schweiz&bounds=47.0068009734239%2C7.38026675047549%2C46.4379930178773%2C6.74186844856443',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchFreiburg" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Kanton%20Genf%2C%20Schweiz&bounds=46.364571957978%2C6.31028291584972%2C46.1285620000364%2C5.95599426104971',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchGenf" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Kanton%20Graubünden%2C%20Schweiz&bounds=47.0651189970794%2C10.4920638273824%2C46.1694910789901%2C8.6510660000018',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchGraubunden" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Kanton%20Jura%2C%20Schweiz&bounds=47.5042939649381%2C7.55747857197824%2C47.1504060005442%2C6.84075103847481',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchJura" />
            </NamedLink>
          </li>
        </ul>
      </div>
      <div className={css.searchesExtra}>
        <ul className={css.list}>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Kanton%20Luzern%2C%20Schweiz&bounds=47.2871959251129%2C8.51371699123482%2C46.7750390354513%2C7.83663312731615',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchLuzern" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Schweiz&bounds=47.70331716%2C10.51147766%2C45.69942548%2C8.24344288',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchOstschweiz" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Kanton%20Tessin%2C%20Schweiz&bounds=46.6324849981809%2C9.15953498663582%2C45.8179850070631%2C8.38213712594365',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchTessin" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Kanton%20Wallis%2C%20Schweiz&bounds=46.654027960236%2C8.47842989309025%2C45.8581840023697%2C6.77079903184969',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchWallis" />
            </NamedLink>
          </li>
          <li className={css.listItem}>
            <NamedLink
              name="SearchPage"
              to={{
                search:
                  '?address=Kanton%20Zürich%2C%20Schweiz&bounds=47.6947499800253%2C8.98495199415534%2C47.1594420176842%2C8.35777802457705',
              }}
              className={css.link}
            >
              <FormattedMessage id="Footer.searchZurich" />
            </NamedLink>
          </li>
        </ul>
      </div>
    </>
  );

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <Logo format="desktop" className={css.logo} />
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPagePhilosphieIdee" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" to={{ hash: '#gewerbe' }} className={css.link}>
                    <FormattedMessage id="Footer.toAboutPageGewerbe" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" to={{ hash: '#wie-funktioniert' }} className={css.link}>
                    <FormattedMessage id="Footer.toAboutPageWieFunktioniert" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="NewListingPage" className={css.link}>
                    <FormattedMessage id="Footer.toNewListingPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>

            <div className={css.infoLinks}>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" to={{ hash: '#werbung' }} className={css.link}>
                    <FormattedMessage id="Footer.toAboutPageWerbung" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" to={{ hash: '#contact' }} className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>

            <div className={css.extraLinks}>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="ImpressumPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.impressum" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
              <FormattedMessage id="Footer.copyright" />
            </NamedLink>
            <div className={css.tosAndPrivacyMobile}>
              <NamedLink name="PrivacyPolicyPage" className={css.privacy}>
                <FormattedMessage id="Footer.privacy" />
              </NamedLink>
              <NamedLink name="TermsOfServicePage" className={css.terms}>
                <FormattedMessage id="Footer.terms" />
              </NamedLink>
              <NamedLink name="ImpressumPage" className={css.terms}>
                <FormattedMessage id="Footer.impressum" />
              </NamedLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
