import React, { Component } from 'react';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';

import { Menu, MenuContent, MenuItem, MenuLabel } from '..';
import css from './SelectSingleFilterPopup.css';

const optionLabel = (options, key) => {
  const option = options.find(o => o.key === key);
  return option ? option.label : key;
};

const getQueryParamName = queryParamNames => {
  return Array.isArray(queryParamNames) ? queryParamNames[0] : queryParamNames;
};

const buildCategoriesHash = categoriesArray => {
  const categoriesHash = {};

  if(Array.isArray(categoriesArray)) {
    categoriesArray.forEach( category => {
      categoriesHash[ category.key ] = { ...category };
      categoriesHash[ category.key ].childCount = 0;
    });

    categoriesArray.forEach( category => {
      if(category.parent) {
        categoriesHash[category.parent].childCount++;
      }
    });
  }

  return categoriesHash;
};

class SelectSingleFilterPopup extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false, unfoldedCategory: null };
    this.categoriesHash = buildCategoriesHash(this.props.options);
    this.onToggleActive = this.onToggleActive.bind(this);
    this.selectOption = this.selectOption.bind(this);
  }

  onToggleActive(isOpen) {
    this.setState({ isOpen: isOpen });
  }

  selectOption(queryParamName, option) {
    if(option && this.categoriesHash[option].childCount > 0){
      this.setState({ unfoldedCategory: option });
      this.setState({ selectedCategory: option });
    } else {
      this.setState({ selectedCategory: option });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      label,
      options,
      queryParamNames,
      initialValues,
      contentPlacementOffset,
    } = this.props;

    const queryParamName = getQueryParamName(queryParamNames);
    const initialValue =
      initialValues && initialValues[queryParamNames] ? initialValues[queryParamNames] : null;

    // resolve menu label text and class
    const menuLabel = initialValue ? optionLabel(options, initialValue) : label;
    const menuLabelClass = initialValue ? css.menuLabelSelected : css.menuLabel;

    const classes = classNames(rootClassName || css.root, className);

    return (
      <Menu
        className={classes}
        useArrow={false}
        contentPlacementOffset={contentPlacementOffset}
        onToggleActive={this.onToggleActive}
        isOpen={this.state.isOpen}
      >
        <MenuLabel className={menuLabelClass}>{menuLabel}</MenuLabel>
        <MenuContent className={css.menuContent}>
          <MenuItem key={'topClearLink'}>
            <button
              className={css.clearMenuItem}
              onClick={() => {
                this.setState({
                  isOpen: false,
                  unfoldedCategory: null,
                  selectedCategory: null,
                });
                this.props.onSelect({ [queryParamName]: null });
              }}
            >
              <FormattedMessage id={'SelectSingleFilter.popupClear'} />
            </button>
            <button
              className={css.cancelButton}
              type="button"
              onClick={() => this.setState({ isOpen: false })}
            >
              <FormattedMessage id={'SelectSingleFilter.cancel'} />
            </button>
            <button
              className={css.submitButton}
              type="button"
              onClick={() => {
                this.setState({ isOpen: false });
                this.props.onSelect({ [queryParamName]: this.state.selectedCategory });
              }}
            >
              <FormattedMessage id={'SelectSingleFilter.submit'} />
            </button>
          </MenuItem>
          {options.map(option => {
            // check if this option is selected
            const currentValue = this.state.selectedCategory || initialValue;
            const selected = currentValue === option.key;
            const parent = option.parent;
            const topParent = parent ? this.categoriesHash[parent].parent : null;
            const isOptionVisible = !parent || parent === this.state.unfoldedCategory ||
              ( this.state.unfoldedCategory && parent && ( parent === this.categoriesHash[this.state.unfoldedCategory].parent ));
            let menuItemClassName = css.menuItem;
            if(parent){
              menuItemClassName = css.menuSubItem;
            }
            if(topParent){
              menuItemClassName = css.menuSubSubItem;
            }

            // menu item border class
            const menuItemBorderClass = selected ? css.menuItemBorderSelected : css.menuItemBorder;

            return isOptionVisible ? (
              <MenuItem key={option.key}>
                <button
                  className={menuItemClassName}
                  onClick={() => this.selectOption(queryParamName, option.key)}
                >
                  <span className={menuItemBorderClass} />
                  {option.label}
                </button>
              </MenuItem>
            ) : null;
          })}
          <MenuItem key={'clearLink'}>
            <button
              className={css.clearMenuItem}
              onClick={() => {
                this.setState({
                  isOpen: false,
                  unfoldedCategory: null,
                  selectedCategory: null,
                });
                this.props.onSelect({ [queryParamName]: null });
              }}
            >
              <FormattedMessage id={'SelectSingleFilter.popupClear'} />
            </button>
            <button
              className={css.cancelButton}
              type="button"
              onClick={() => this.setState({ isOpen: false })}
            >
              <FormattedMessage id={'SelectSingleFilter.cancel'} />
            </button>
            <button
              className={css.submitButton}
              type="button"
              onClick={() => {
                this.setState({ isOpen: false });
                this.props.onSelect({ [queryParamName]: this.state.selectedCategory });
              }}
            >
              <FormattedMessage id={'SelectSingleFilter.submit'} />
            </button>
          </MenuItem>
        </MenuContent>
      </Menu>
    );
  }
}

SelectSingleFilterPopup.defaultProps = {
  rootClassName: null,
  className: null,
  initialValues: null,
  contentPlacementOffset: 0,
};

SelectSingleFilterPopup.propTypes = {
  rootClassName: string,
  className: string,
  queryParamNames: arrayOf(string).isRequired,
  label: node.isRequired,
  onSelect: func.isRequired,
  options: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ).isRequired,
  initialValues: object,
  contentPlacementOffset: number,
};

export default SelectSingleFilterPopup;
