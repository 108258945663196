import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  Page,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  NamedLink,
} from '../../components';
import { ProfileSettingsForm } from '../../forms';
import { TopbarContainer } from '../../containers';

import {
  updateProfile,
  uploadImage,
  fetchProfileListing,
  requestUpdateProfileListing,
  requestProfileImageUpload,
  updateProfileImageOrder,
  removeProfileListingImage,
} from './ProfileSettingsPage.duck';
import css from './ProfileSettingsPage.css';

const onImageUploadHandler = (values, fn) => {
  const { id, imageId, file } = values;
  if (file) {
    fn({ id, imageId, file });
  }
};

export class ProfileSettingsPageComponent extends Component {
  render() {
    const {
      currentUser,
      image,
      onManageDisableScrolling,
      onImageUpload,
      onUpdateProfile,
      scrollingDisabled,
      updateInProgress,
      updateProfileError,
      uploadImageError,
      uploadInProgress,
      profileListing,
      images,
      imageOrder,
      removedImageIds,
      fetchProfileListingError,
      fetchProfileListingInProgress,
      updateProfileListingError,
      updateProfileListingInProgress,
      uploadProfileImageError,
      uploadProfileImageInProgress,
      onUpdateProfileListing,
      onProfileImageUpload,
      onUpdateProfileImageOrder,
      onRemoveProfileListingImage,
      intl,
    } = this.props;

    const handleSubmit = values => {
      const { firstName, lastName, bio: rawBio } = values;

      // Ensure that the optional bio is a string
      const bio = rawBio || '';

      const profile = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        bio,
      };
      const uploadedImage = this.props.image;

      // Update profileImage only if file system has been accessed
      const updatedValues =
        uploadedImage && uploadedImage.imageId && uploadedImage.file
          ? { ...profile, profileImageId: uploadedImage.imageId }
          : profile;

      onUpdateProfile(updatedValues);
    };

    const user = ensureCurrentUser(currentUser);
    const { firstName, lastName, bio } = user.attributes.profile;

    const profileImage =
      profileListing && Array.isArray(profileListing.images) && profileListing.images.length > 0 ?
        { imageId: profileListing.images[0].id } : {};

    const profileSettingsForm = user.id ? (
      <ProfileSettingsForm
        className={css.form}
        currentUser={currentUser}
        initialValues={{ firstName, lastName, bio, profileImage }}
        profileImage={profileImage}
        onManageDisableScrolling={onManageDisableScrolling}
        onImageUpload={e => onImageUploadHandler(e, onImageUpload)}
        uploadInProgress={uploadInProgress}
        updateInProgress={updateInProgress}
        uploadImageError={uploadImageError}
        updateProfileError={updateProfileError}
        onSubmit={handleSubmit}
        profileListing={profileListing}
        images={images}
        imageOrder={imageOrder}
        removedImageIds={removedImageIds}
        fetchProfileListingError={fetchProfileListingError}
        fetchProfileListingInProgress={fetchProfileListingInProgress}
        updateProfileListingError={updateProfileListingError}
        updateProfileListingInProgress={updateProfileListingInProgress}
        uploadProfileImageError={uploadProfileImageError}
        uploadProfileImageInProgress={uploadProfileImageInProgress}
        onUpdateProfileListing={onUpdateProfileListing}
        onProfileImageUpload={onProfileImageUpload}
        onUpdateProfileImageOrder={onUpdateProfileImageOrder}
        onRemoveProfileListingImage={onRemoveProfileListingImage}
      />
    ) : null;

    const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });

    return (
      <Page className={css.root} title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ProfileSettingsPage" />
            <UserNav selectedPageName="ProfileSettingsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>
                  <FormattedMessage id="ProfileSettingsPage.heading" />
                </h1>
                {user.id ? (
                  <NamedLink
                    className={css.profileLink}
                    name="ProfilePage"
                    params={{ id: user.id.uuid }}
                  >
                    <FormattedMessage id="ProfileSettingsPage.viewProfileLink" />
                  </NamedLink>
                ) : null}
              </div>
              {profileSettingsForm}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ProfileSettingsPageComponent.defaultProps = {
  currentUser: null,
  uploadImageError: null,
  updateProfileError: null,
  image: null,
};

const { bool, func, object, shape, string } = PropTypes;

ProfileSettingsPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  image: shape({
    id: string,
    imageId: propTypes.uuid,
    file: object,
    uploadedImage: propTypes.image,
  }),
  onImageUpload: func.isRequired,
  onUpdateProfile: func.isRequired,
  scrollingDisabled: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const {
    image,
    uploadImageError,
    uploadInProgress,
    updateInProgress,
    updateProfileError,
    profileListing,
    images,
    imageOrder,
    removedImageIds,
    fetchProfileListingError,
    fetchProfileListingInProgress,
    updateProfileListingError,
    updateProfileListingInProgress,
    uploadProfileImageError,
    uploadProfileImageInProgress,
  } = state.ProfileSettingsPage;
  return {
    currentUser,
    image,
    scrollingDisabled: isScrollingDisabled(state),
    updateInProgress,
    updateProfileError,
    uploadImageError,
    uploadInProgress,
    profileListing,
    images,
    imageOrder,
    removedImageIds,
    fetchProfileListingError,
    fetchProfileListingInProgress,
    updateProfileListingError,
    updateProfileListingInProgress,
    uploadProfileImageError,
    uploadProfileImageInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onImageUpload: data => dispatch(uploadImage(data)),
  onUpdateProfile: data => dispatch(updateProfile(data)),
  onUpdateProfileListing: data => dispatch(requestUpdateProfileListing(data)),
  onProfileImageUpload: data => dispatch(requestProfileImageUpload(data)),
  onUpdateProfileImageOrder: imageOrder => dispatch(updateProfileImageOrder(imageOrder)),
  onRemoveProfileListingImage: imageId => dispatch(removeProfileListingImage(imageId)),
});

const ProfileSettingsPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(ProfileSettingsPageComponent);

ProfileSettingsPage.loadData = () => {
  return fetchProfileListing();
};

export default ProfileSettingsPage;
