import cloneDeep from 'lodash/cloneDeep';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import omit from 'lodash/omit';
import * as log from '../../util/log';

const requestAction = actionType => params => ({ type: actionType, payload: { params } });

const successAction = actionType => result => ({ type: actionType, payload: result.data });

const errorAction = actionType => error => ({ type: actionType, payload: error, error: true });

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/ProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfileSettingsPage/UPDATE_PROFILE_ERROR';

export const FETCH_PROFILE_LISTING_REQUEST = 'app/ProfileSettingsPage/FETCH_PROFILE_LISTING_REQUEST';
export const FETCH_PROFILE_LISTING_SUCCESS = 'app/ProfileSettingsPage/FETCH_PROFILE_LISTING_SUCCESS';
export const FETCH_PROFILE_LISTING_ERROR = 'app/ProfileSettingsPage/FETCH_PROFILE_LISTING_ERROR';

export const UPDATE_PROFILE_LISTING_REQUEST = 'app/EditListingPage/UPDATE_PROFILE_LISTING_REQUEST';
export const UPDATE_PROFILE_LISTING_SUCCESS = 'app/EditListingPage/UPDATE_PROFILE_LISTING_SUCCESS';
export const UPDATE_PROFILE_LISTING_ERROR = 'app/EditListingPage/UPDATE_PROFILE_LISTING_ERROR';

export const UPLOAD_PROFILE_IMAGE_REQUEST = 'app/EditListingPage/UPLOAD_PROFILE_IMAGE_REQUEST';
export const UPLOAD_PROFILE_IMAGE_SUCCESS = 'app/EditListingPage/UPLOAD_PROFILE_IMAGE_SUCCESS';
export const UPLOAD_PROFILE_IMAGE_ERROR = 'app/EditListingPage/UPLOAD_PROFILE_IMAGE_ERROR';

export const UPDATE_PROFILE_IMAGE_ORDER = 'app/EditListingPage/UPDATE_PROFILE_IMAGE_ORDER';

export const REMOVE_PROFILE_LISTING_IMAGE = 'app/EditListingPage/REMOVE_PROFILE_LISTING_IMAGE';

// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  profileListing: null,
  fetchProfileListingError: null,
  fetchProfileListingInProgress: false,
  updateProfileListingError: null,
  updateProfileListingInProgress: false,
  images: {},
  imageOrder: [],
  removedImageIds: [],
  uploadProfileImageError: null,
  uploadProfileImageInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    case UPDATE_PROFILE_LISTING_REQUEST:
      return { ...state, updateProfileListingInProgress: true, updateProfileListingError: null };
    case UPDATE_PROFILE_LISTING_SUCCESS:
      return {
        ...state,
        images: {},
        imageOrder: [],
        removedImageIds: [],
        updateProfileListingInProgress: false
      };
    case UPDATE_PROFILE_LISTING_ERROR:
      return { ...state, updateProfileListingInProgress: false, updateProfileListingError: payload };

    case FETCH_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        fetchProfileListingInProgress: true,
        fetchProfileListingError: null,
      };
    case FETCH_PROFILE_LISTING_SUCCESS:
      const { profileListing } = payload;
      return {
        ...state,
        profileListing,
        fetchProfileListingInProgress: false,
        fetchProfileListingError: null,
      };
    case FETCH_PROFILE_LISTING_ERROR:
      return {
        ...state,
        profileListing: null,
        fetchProfileListingInProgress: false,
        fetchProfileListingError: payload,
      };

    case UPLOAD_PROFILE_IMAGE_REQUEST: {
      // payload.params: { id: 'tempId', file }
      const images = {
        ...state.images,
        [payload.params.id]: { ...payload.params },
      };
      return {
        ...state,
        images,
        imageOrder: state.imageOrder.concat([payload.params.id]),
        uploadProfileImageInProgress: true,
        uploadProfileImageError: null,
      };
    }
    case UPLOAD_PROFILE_IMAGE_SUCCESS: {
      // payload.params: { id: 'tempId', imageId: 'some-real-id'}
      const { id, imageId } = payload;
      const file = state.images[id].file;
      const images = { ...state.images, [id]: { id, imageId, file } };
      return {
        ...state,
        images,
        uploadProfileImageInProgress: false,
        uploadProfileImageError: null
      };
    }
    case UPLOAD_PROFILE_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      const { id, error } = payload;
      const imageOrder = state.imageOrder.filter(i => i !== id);
      const images = omit(state.images, id);
      return {
        ...state,
        imageOrder,
        images,
        uploadProfileImageInProgress: false,
        uploadProfileImageError: error
      };
    }
    case UPDATE_PROFILE_IMAGE_ORDER:
      return { ...state, imageOrder: payload.imageOrder };

    case REMOVE_PROFILE_LISTING_IMAGE: {
      const id = payload.imageId;

      // Only mark the image removed if it hasn't been added to the
      // listing already
      const removedImageIds = state.images[id]
        ? state.removedImageIds
        : state.removedImageIds.concat(id);

      // Always remove from the draft since it might be a new image to
      // an existing listing.
      const images = omit(state.images, id);
      const imageOrder = state.imageOrder.filter(i => i !== id);

      return { ...state, images, imageOrder, removedImageIds };
    }

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

export const updateProfileImageOrder = imageOrder => ({
  type: UPDATE_PROFILE_IMAGE_ORDER,
  payload: { imageOrder },
});

export const removeProfileListingImage = imageId => ({
  type: REMOVE_PROFILE_LISTING_IMAGE,
  payload: { imageId },
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

// SDK method: ownListings.update
export const updateProfileListing = requestAction(UPDATE_PROFILE_LISTING_REQUEST);
export const updateProfileListingSuccess = successAction(UPDATE_PROFILE_LISTING_SUCCESS);
export const updateProfileListingError = errorAction(UPDATE_PROFILE_LISTING_ERROR);

// SDK method: sdk.ownListings.query
export const fetchProfileListingRequest = params => ({
  type: FETCH_PROFILE_LISTING_REQUEST,
  payload: { params },
});
export const fetchProfileListingSuccess = result => ({
  type: FETCH_PROFILE_LISTING_SUCCESS,
  payload: result.data,
});
export const fetchProfileListingError = error => ({
  type: FETCH_PROFILE_LISTING_ERROR,
  payload: error,
  error: true,
});

// SDK method: images.upload
export const uploadProfileImage = requestAction(UPLOAD_PROFILE_IMAGE_REQUEST);
export const uploadProfileImageSuccess = successAction(UPLOAD_PROFILE_IMAGE_SUCCESS);
export const uploadProfileImageError = errorAction(UPLOAD_PROFILE_IMAGE_ERROR);

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const updateProfile = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    const extendedPayload = cloneDeep(actionPayload);
    if(!extendedPayload.publicData) {
      extendedPayload.publicData = {};
    }
    if(extendedPayload.firstName){
      extendedPayload.publicData.firstName = extendedPayload.firstName;
    }
    if(extendedPayload.lastName){
      extendedPayload.publicData.lastName = extendedPayload.lastName;
    }

    return sdk.currentUser
      .updateProfile(extendedPayload, queryParams)
      .then(response => {
        dispatch(updateProfileSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};

// Update the given tab of the wizard with the given data. This saves
// the data to the listing, and marks the tab updated so the UI can
// display the state.
export function requestUpdateProfileListing(data) {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileListing(data));
    let updateResponse;
    return sdk.ownListings
      .update(data)
      .then(response => {
        updateResponse = response;
        return dispatch(fetchProfileListing());
      })
      .then(() => {
        dispatch(updateProfileListingSuccess(updateResponse));
        return updateResponse;
      })
      .catch(e => {
        log.error(e, 'update-profile-listing-failed', { listingData: data });
        return dispatch(updateProfileListingError(storableError(e)));
      });
  };
}

export const fetchProfileListing = () => {
  return (dispatch, getState, sdk) => {
    dispatch(fetchProfileListingRequest());
    return sdk.ownListings
      .query({
        include: ['images'],
        'fields.image': [
          'variants.landscape-crop',
          'variants.landscape-crop2x',
          'variants.square-small',
          'variants.square-small2x'
        ],
      })
      .then(response => {
        let profileListing = null;

        const entities = denormalisedResponseEntities(response);
        if (entities.length < 1) {
          throw new Error('At least one listing for the current user.');
        }

        entities.forEach( entity => {
          if(entity.attributes.publicData.type && entity.attributes.publicData.type === 'profile'){
            profileListing = entity;
          }
        });

        if( !profileListing ){
          throw new Error('Profile listing was not found. Please logout and login again to have one created for you.');
        }

        dispatch(fetchProfileListingSuccess({ data: { profileListing }}));

        return response;
      })
      .catch(e => dispatch(fetchProfileListingError(storableError(e))));
  };
};

// Images return imageId which we need to map with previously generated temporary id
export function requestProfileImageUpload(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadProfileImage(actionPayload));
    return sdk.images
      .upload({ image: actionPayload.file })
      .then(resp => dispatch(uploadProfileImageSuccess({ data: { id, imageId: resp.data.data.id } })))
      .catch(e => dispatch(uploadProfileImageError({ id, error: storableError(e) })));
  };
}
